import longUrl2ShortUrl from '@/common/longUrl2ShortUrlUtils.js'
import { qiniuBase } from '~api-config'

export default {
  methods: {
    closeErrorCorrectionDialog() {
      this.errorCorrectionDialog = false
    },
    // 纠错
    openErrorCorrectionDialog(name, unit, value) {
      this.errorCorrection = {
        name: name,
        time: parseInt(this.time) - 1,
        address: this.address,
        unit: unit,
        value: value,
      }
      this.errorCorrectionDialog = true
    },
    // 设置键盘的标题
    setKeyboardTitle(value, key) {
      if (this.titleObj.key !== key) {
        return
      }
      let title = ''
      if (value) {
        if (this.titleObj.yes) {
          title = this.titleObj.yes
          title = title.replace(/###/g, value)
        }
      } else {
        if (this.titleObj.no) {
          title = this.titleObj.no
        }
      }
      this.keyboardTitle = title
    },
    // 重置
    reset() {
      location.reload()
    },
    // 将元转换成万元
    yuanToWan(money) {
      if (money === '') {
        money = 0
      }
      money = parseFloat(money)
      const wan = parseInt(money / 10000)
      let yuan = money - wan * 10000
      yuan = parseFloat(yuan.toFixed(2))
      return {
        wan: wan,
        yuan: yuan,
      }
    },
    setZero(name) {
      if (name === 'BeiFuYangRenShengHuoFei.params.income') {
        if (this.BeiFuYangRenShengHuoFei.params.income === 0 || this.BeiFuYangRenShengHuoFei.params.income === '0') {
          this.BeiFuYangRenShengHuoFei.params.income = ''
        }
      } else if (this[name] === 0 || this[name] === '0') {
        this[name] = ''
      }
    },
    resutlInput(type) {
      this[type + 'Result'] = this[type + 'Result'].replace(/[^\d.]/g, '')
      if (this[type + 'Result'] === '') {
        // this[type + 'Result'] = 0
      }
      this[type + 'Change'] = true
      this.getResult()
    },
    // 数字键盘组件输入事件
    onInput(value) {
      const keys = this.keyboard.split('.')
      // const setDeepProp = (obj, path, value) => {
      //   var curr = obj
      //   for (let depth = 0; depth < path.length - 1; depth++) {
      //     curr = curr[path[depth]]
      //   }
      //   curr[path[path.length - 1]] = curr[path[path.length - 1]] + value
      // }
      // const oldO = this
      // setDeepProp(oldO, keys, value)
      if (keys.length === 1) {
        this[keys[0]] += value + ''
        if (this.resultInputFlag) {
          this.resutlInput(keys[0].substr(0, keys[0].length - 6))
        }
      } else if (keys.length === 3) {
        this[keys[0]][keys[1]][keys[2]] += value + ''
      } else if (keys.length === 4) {
        this[keys[0]][keys[1]][keys[2]][keys[3]] += value + ''
      } else if (keys.length === 5) {
        this[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]] += value + ''
      }
    },
    // 数字键盘组件删除事件
    onDelete(value) {
      const keys = this.keyboard.split('.')
      var money = ''
      if (keys.length === 1) {
        money = this[keys[0]].toString()
        this[keys[0]] = money.substring(0, money.length - 1)
        if (this.resultInputFlag) {
          this.resutlInput(keys[0].substr(0, keys[0].length - 6))
        }
      } else if (keys.length === 3) {
        money = this[keys[0]][keys[1]][keys[2]].toString()
        this[keys[0]][keys[1]][keys[2]] = money.substring(0, money.length - 1)
      } else if (keys.length === 4) {
        money = this[keys[0]][keys[1]][keys[2]][keys[3]].toString()
        this[keys[0]][keys[1]][keys[2][keys[3]]] = money.substring(0, money.length - 1)
      } else if (keys.length === 5) {
        money = this[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]].toString()
        this[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]] = money.substring(0, money.length - 1)
      }
    },
    // 开始时间选择器点击确定事件
    startTimeConfirm(date) {
      this.time = date
      this.startTimeShow = false
      this.handleSelect({ shortName: this.address })
    },
    clickItem(item) {
      this.addHistory()
      const { condition, type } = item
      const conditionObj = JSON.parse(condition)
      console.log(conditionObj)
      if (type === 'deathCompensation') {
        const {
          age,
          time,
          address,
          SiWangPeiChangJin,
          SangZangFei,
          YiLiaoFei,
          JiaoTongFei,
          ZhuSuFei,
          JingShenSunHaiFuWeiJin,
          WuGongFei,
          ZhuYuanHuLiFei,
          ChuYuanHuLiFei,
          BeiFuYangRenShengHuoFei,
          WuGongFeiResult,
          ZhuYuanHuLiFeiResult,
          ChuYuanHuLiFeiResult,
          SiWangPeiChangJinResult,
          SangZangFeiResult,
          BeiFuYangRenShengHuoFeiResult,
        } = conditionObj
        console.log(BeiFuYangRenShengHuoFei)
        this.age = age
        this.time = time
        this.dateTime = new Date(`${this.time}-01-01`)
        console.log(this.dateTime)
        this.address = address
        this.SangZangFei = SangZangFei
        this.YiLiaoFei = YiLiaoFei
        this.JiaoTongFei = JiaoTongFei
        this.ZhuSuFei = ZhuSuFei
        this.JingShenSunHaiFuWeiJin = JingShenSunHaiFuWeiJin
        this.WuGongFei = WuGongFei
        this.ZhuYuanHuLiFei = ZhuYuanHuLiFei
        this.ChuYuanHuLiFei = ChuYuanHuLiFei
        this.BeiFuYangRenShengHuoFei = JSON.parse(JSON.stringify(BeiFuYangRenShengHuoFei))
        setTimeout(() => {
          this.BeiFuYangRenShengHuoFei = JSON.parse(JSON.stringify(BeiFuYangRenShengHuoFei))
          this.SiWangPeiChangJin = JSON.parse(JSON.stringify(SiWangPeiChangJin))
          setTimeout(() => {
            if (WuGongFeiResult) {
              this.WuGongFeiResult = WuGongFeiResult
              this.resutlInput('WuGongFei')
            }
            if (ZhuYuanHuLiFeiResult) {
              this.ZhuYuanHuLiFeiResult = ZhuYuanHuLiFeiResult
              this.resutlInput('ZhuYuanHuLiFei')
            }
            if (ChuYuanHuLiFeiResult) {
              this.ChuYuanHuLiFeiResult = ChuYuanHuLiFeiResult
              this.resutlInput('ChuYuanHuLiFei')
            }
            if (SiWangPeiChangJinResult) {
              this.SiWangPeiChangJinResult = SiWangPeiChangJinResult
              this.resutlInput('SiWangPeiChangJin')
            }
            if (SangZangFeiResult) {
              this.SangZangFeiResult = SangZangFeiResult
              this.resutlInput('SangZangFei')
            }
            if (BeiFuYangRenShengHuoFeiResult) {
              this.BeiFuYangRenShengHuoFeiResult = BeiFuYangRenShengHuoFeiResult
              this.resutlInput('BeiFuYangRenShengHuoFei')
            }
          }, 500)
        }, 1500)
      }
    },
    addHistory() {
      const type = 'deathCompensation'
      const {
        age,
        time,
        address,
        SiWangPeiChangJin,
        SangZangFei,
        YiLiaoFei,
        JiaoTongFei,
        ZhuSuFei,
        JingShenSunHaiFuWeiJin,
        WuGongFei,
        ZhuYuanHuLiFei,
        ChuYuanHuLiFei,
        BeiFuYangRenShengHuoFei,
        WuGongFeiResult,
        ZhuYuanHuLiFeiResult,
        ChuYuanHuLiFeiResult,
        SiWangPeiChangJinResult,
        SangZangFeiResult,
        BeiFuYangRenShengHuoFeiResult,
      } = this
      const conditionObj = {
        age,
        time,
        address,
        SiWangPeiChangJin,
        SangZangFei,
        YiLiaoFei,
        JiaoTongFei,
        ZhuSuFei,
        JingShenSunHaiFuWeiJin,
        WuGongFei,
        ZhuYuanHuLiFei,
        ChuYuanHuLiFei,
        BeiFuYangRenShengHuoFei,
        WuGongFeiResult,
        ZhuYuanHuLiFeiResult,
        ChuYuanHuLiFeiResult,
        SiWangPeiChangJinResult,
        SangZangFeiResult,
        BeiFuYangRenShengHuoFeiResult,
      }
      let title = ' ' + age + '岁 ' + time + '年 ' + address
      title += ' 死亡赔偿'
      if (this.result && this.result.toString().trim() !== '0') {
        this.$axios
          .post(`${this.$base}/management/utils/history/add`, {
            type,
            condition: JSON.stringify(conditionObj),
            title,
          })
          .then((res) => {
            if (res.data.code === 200) {
              // this.$bus.$emit('add-history-suc', 'deathCompensation')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 设置字体大小
    setFontSize(size) {
      this.fontSize = size
    },
    // 增加数据
    addIncome(typeList) {
      const type = typeList.split('.')[0]
      let address = ''
      const data = {}
      if (type === 'SiWangPeiChangJin') {
        if (this.SiWangPeiChangJin.params.address === '' || this.SiWangPeiChangJin.params.income === '' || this.time === '') {
          return
        }
        address = this.SiWangPeiChangJin.params.address
        data.dataContent = this.SiWangPeiChangJin.params.income
        data.year = parseInt(this.time) - 1
        data.dataType = '人均可支配收入'
      } else if (type === 'BeiFuYangRenShengHuoFei') {
        if (this.BeiFuYangRenShengHuoFei.params.address === '' || this.BeiFuYangRenShengHuoFei.params.income === '' || this.time === '') {
          return
        }
        address = this.BeiFuYangRenShengHuoFei.params.address
        data.dataContent = this.BeiFuYangRenShengHuoFei.params.income
        data.year = parseInt(this.time) - 1
        data.dataType = '人均年消费支出'
      } else if (type === 'SangZangFei') {
        if (this.SangZangFei.params.address === '' || this.SangZangFei.params.time === '' || this.SangZangFei.params.industry === '' || this.SangZangFei.params.money === '') {
          return
        }
        address = this.SangZangFei.params.address
        data.dataContent = this.SangZangFei.params.money
        data.year = this.SangZangFei.params.time
        data.dataType = '平均工资'
        data.relationAttrType = '行业'
        data.relationAttrValue = this.SangZangFei.params.industry
      } else if (type === 'ShangCanPeiChang') {
        if (this.address === '' || this.ShangCanPeiChang.params.income === '' || this.time === '') {
          return
        }
        address = this.address
        data.dataContent = this.ShangCanPeiChang.params.income
        data.year = parseInt(this.time) - 1
        data.dataType = '人均可支配收入'
      }
      data.province = address
      // if (address.length === 3) {
      //   data.province = address
      //   data.city = address
      //   data.district = address
      // } else {
      //   data.city = address
      // }
      this.$axios.post(`${this.$base}/admin/peopleData/add`, data).then((res) => {})
    },
    // 设置地址
    handleSelect(item) {
      this.address = item.shortName
      this.SiWangPeiChangJin.params.address = item.shortName
      this.BeiFuYangRenShengHuoFei.params.address = item.shortName
      this.SangZangFei.params.address = item.shortName
      const address = JSON.parse(localStorage.getItem('province'))
      const params = {
        currentPage: 1,
        pageSize: 10,
        province: address,
        // city: name,
        // district: address[2],
        dataType: '人均年消费支出',
      }
      if (this.time) {
        params.year = parseInt(this.time) - 1
      }
      this.$axios
        .get(`${this.$base}/admin/peopleData/query`, {
          params: params,
        })
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.list.length > 0) {
              this.expenditure = res.data.data.list[0].dataContent + ''
              this.BeiFuYangRenShengHuoFei.params.income = res.data.data.list[0].dataContent + ''
            } else {
              this.expenditure = 0 + ''
              this.BeiFuYangRenShengHuoFei.params.income = 0 + ''
            }
          }
        })
      const params2 = {
        currentPage: 1,
        pageSize: 10,
        province: address,
        // city: address[1],
        // district: address[2],
        dataType: '人均可支配收入',
      }
      if (this.time) {
        params2.year = parseInt(this.time) - 1
      }
      this.$axios
        .get(`${this.$base}/admin/peopleData/query`, {
          params: params2,
        })
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.list.length > 0) {
              this.income = res.data.data.list[0].dataContent + ''
              this.SiWangPeiChangJin.params.income = res.data.data.list[0].dataContent + ''
              this.ShangCanPeiChang.params.income = res.data.data.list[0].dataContent + ''
            } else {
              this.income = 0 + ''
              this.SiWangPeiChangJin.params.income = 0 + ''
              this.ShangCanPeiChang.params.income = 0 + ''
            }
          }
        })
      if (address === '江苏') {
        const params3 = {
          currentPage: 1,
          pageSize: 10,
          province: address,
          // city: address[1],
          // district: address[2],
          dataType: '全省居民人均经营净收入',
        }
        if (this.time) {
          params3.year = parseInt(this.time) - 1
        }
        this.$axios
          .get(`${this.$base}/admin/peopleData/query`, {
            params: params3,
          })
          .then((res) => {
            if (res.data.code === 200) {
              if (res.data.data.list.length > 0) {
                this.SiWangPeiChangJin.params.income2 = res.data.data.list[0].dataContent
              } else {
                this.SiWangPeiChangJin.params.income2 = '0'
              }
            }
          })

        const params4 = {
          currentPage: 1,
          pageSize: 10,
          province: address,
          // city: address[1],
          // district: address[2],
          dataType: '全省平均负担系数',
        }

        if (this.time) {
          params4.year = parseInt(this.time) - 1
        }
        this.$axios
          .get(`${this.$base}/admin/peopleData/query`, {
            params: params4,
          })
          .then((res) => {
            if (res.data.code === 200) {
              if (res.data.data.list.length > 0) {
                this.SiWangPeiChangJin.params.coefficient = res.data.data.list[0].dataContent
              } else {
                this.SiWangPeiChangJin.params.coefficient = '0'
              }
            }
          })

        const params5 = {
          currentPage: 1,
          pageSize: 10,
          province: address,
          // city: address[1],
          // district: address[2],
          dataType: '全省居民人均工资性收入',
        }
        if (this.time) {
          params5.year = parseInt(this.time) - 1
        }
        this.$axios
          .get(`${this.$base}/admin/peopleData/query`, {
            params: params5,
          })
          .then((res) => {
            if (res.data.code === 200) {
              if (res.data.data.list.length > 0) {
                this.SiWangPeiChangJin.params.income = res.data.data.list[0].dataContent
              } else {
                this.SiWangPeiChangJin.params.income = '0'
              }
            }
          })
      }
    },
    upAddress(address) {
      this.address = address
      this.SiWangPeiChangJin.params.address = address
      this.BeiFuYangRenShengHuoFei.params.address = address
      this.SangZangFei.params.address = address
    },
    // 计算结果
    getResult(name) {
      if (name === '') {
      } else {
        this[name + 'Change'] = false
      }
      // 结果
      let result = 0
      let law = ''
      let text = ''
      // 交通费
      if (this.JiaoTongFei.checked) {
        const data = this.getJiaoTongFei('JiaoTongFei')
        result += data.result
        law += data.law
        text += `交通费：${data.result}\n`
      }
      // 医疗费
      let YiLiaoFeiResult = 0
      if (this.YiLiaoFei.checked) {
        const data = this.getJiaoTongFei('YiLiaoFei')
        YiLiaoFeiResult = data.result
        law += data.law
        text += `医疗费：${data.result}\n`
      }
      // 住宿费
      if (this.ZhuSuFei.checked) {
        const data = this.getJiaoTongFei('ZhuSuFei')
        result += data.result
        law += data.law
        text += `住宿费：${data.result}\n`
      }
      // 精神损害抚慰金
      if (this.JingShenSunHaiFuWeiJin.checked) {
        const data = this.getJiaoTongFei('JingShenSunHaiFuWeiJin')
        result += data.result
        law += data.law
        text += `精神损害抚慰金：${data.result}\n`
      }
      // 误工费
      if (this.WuGongFei.checked) {
        const data = this.getWuGongFei('WuGongFei')
        result += data.result
        law += data.law
        text += `误工费，${data.text}\n`
      }
      // 住院护理费
      if (this.ZhuYuanHuLiFei.checked) {
        const data = this.getWuGongFei('ZhuYuanHuLiFei')
        result += data.result
        law += data.law
        text += `住院护理费，${data.text}\n`
      }
      // 出院护理费
      if (this.ChuYuanHuLiFei.checked) {
        const data = this.getWuGongFei('ChuYuanHuLiFei')
        result += data.result
        law += data.law
        text += `出院护理费，${data.text}\n`
      }
      // 被扶养人生活费
      if (this.BeiFuYangRenShengHuoFei.checked) {
        const data = this.getBeiFuYangRenShengHuoFei()
        result += data.result
        law += data.law
        text += `被扶养人生活费\n${data.text}\n`
      }
      // 死亡赔偿金
      if (this.SiWangPeiChangJin.checked) {
        const data = this.getSiWangPeiChangJin()
        result += data.result
        law += data.law
        text += `死亡赔偿金，${data.text}\n`
      }
      // 丧葬费
      if (this.SangZangFei.checked) {
        const data = this.getSangZangFei()
        result += data.result
        law += data.law
        text += `丧葬费，${data.text}\n`
      }
      // 伤残赔偿
      if (this.ShangCanPeiChang.checked) {
        const data = this.getShangCanPeiChang()
        result += data.result
        law += data.law
        text += `伤残赔偿，${data.text}\n`
      }
      // 交强险分摊
      let tempText = ''
      if (this.insurance) {
        if (this.SiWangPeiChangJin.checked) {
          if (this.isResponsibility === 1) {
            result = result - 180000
            tempText += '有责任 死亡伤残赔偿限额：-180000元；'
          } else if (this.isResponsibility === 2) {
            result = result - 18000
            tempText += '无责任 死亡伤残赔偿限额：-18000元；'
          }
        }
        if (this.YiLiaoFei.checked) {
          if (this.isResponsibility === 1) {
            YiLiaoFeiResult = YiLiaoFeiResult - 18000
            tempText += '有责任 医疗费用赔偿限额：-18000元；'
          } else if (this.isResponsibility === 2) {
            YiLiaoFeiResult = YiLiaoFeiResult - 1800
            tempText += '无责任 医疗费用赔偿限额：-1800元；'
          }
        }
      }
      if (YiLiaoFeiResult < 0) {
        YiLiaoFeiResult = 0
      }
      result += YiLiaoFeiResult
      if (tempText !== '') {
        text += '交强险责任分摊' + tempText + '\n'
      }
      if (result < 0) {
        result = 0
      }
      result = parseFloat(parseFloat(result).toFixed(2))
      this.result = result
      this.law = law
      this.text = text
      this.resultObj = {
        text: text,
        law: law,
        result: result,
        address: this.address,
        time: this.time,
        age: this.age,
        income: this.income,
        expenditure: this.expenditure,
        insurance: this.insurance,
        isResponsibility: this.isResponsibility,
      }
    },

    // 计算交通费 || 医疗费 || 住宿费 || 精神损害抚慰金
    getJiaoTongFei(type) {
      let result = 0
      let law = ''
      if (this[type].params.money) {
        result = this[type].params.money
      }
      if (this[type].law) {
        law = this[type].law + '<br />'
      }
      result = parseFloat(parseFloat(result).toFixed(2))
      return {
        result: result,
        law: law,
      }
    },

    // 误工费 || 住院护理费 || 出院护理费
    getWuGongFei(type) {
      let result = 0
      let law = ''
      let text = ''
      const index = this[type].params.checked - 1
      if (this[type].params.list[index].day && this[type].params.list[index].money) {
        result += this[type].params.list[index].day * this[type].params.list[index].money
      }
      result = parseFloat(parseFloat(result).toFixed(2))
      if (this[type + 'Change']) {
        result = parseFloat(parseFloat(this[type + 'Result']).toFixed(2))
      } else {
        this[type + 'Result'] = result
      }
      if (isNaN(result)) {
        result = 0
      }
      if (this[type].law) {
        law = this[type].law + '<br />'
      }
      switch (type) {
        case 'WuGongFei':
          if (index === 0) {
            text = `有固定收入受害人工资${this[type].params.list[index].money}元/天*误工天数${this[type].params.list[index].day}天`
          } else if (index === 1) {
            text = `能证明 受害人最近三年平均收入${this[type].params.list[index].money}元/天*误工天数${this[type].params.list[index].day}天`
          } else if (index === 2) {
            text = `不能证明 相同或者相近行业上一年职工的平均工资${this[type].params.list[index].money}元/天*误工天数${this[type].params.list[index].day}天`
          }
          break
        case 'ZhuYuanHuLiFei':
          if (index === 0) {
            text = `护理人员有收入 护理人工资${this[type].params.list[index].money}元/天*护理期限${this[type].params.list[index].day}天`
          } else if (index === 1) {
            text = `雇佣护工 护理标准${this[type].params.list[index].money}元/天*护理期限${this[type].params.list[index].day}天`
          }
          break
        case 'ChuYuanHuLiFei':
          if (index === 0) {
            text = `护理人员有收入 护理人工资${this[type].params.list[index].money}元/天*护理期限${this[type].params.list[index].day}天`
          } else if (index === 1) {
            text = `雇佣护工 护理标准${this[type].params.list[index].money}元/天*护理期限${this[type].params.list[index].day}天`
          }
          break
      }
      text += `  合计${result}元`
      return {
        result: result,
        law: law,
        text: text,
      }
    },

    // 被扶养人生活费设置地址
    handleSelectBeiFuYangRenShengHuoFei(item) {
      this.BeiFuYangRenShengHuoFei.params.address = item.shortName
      const address = this.BeiFuYangRenShengHuoFei.params.address
      const params2 = {
        currentPage: 1,
        pageSize: 10,
        province: address,
        dataType: '人均年消费支出',
      }
      if (this.time) {
        params2.year = parseInt(this.time) - 1
      }
      this.$axios
        .get(`${this.$base}/admin/peopleData/query`, {
          params: params2,
        })
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.list.length > 0) {
              this.BeiFuYangRenShengHuoFei.params.income = res.data.data.list[0].dataContent
            } else {
              this.BeiFuYangRenShengHuoFei.params.income = ''
            }
          }
        })
    },
    upAddressBeiFuYangRenShengHuoFei(address) {
      this.BeiFuYangRenShengHuoFei.params.address = address
    },
    // 被扶养人生活费
    getBeiFuYangRenShengHuoFei() {
      let result = 0
      let law = ''
      let text = ''
      const list = [] // 所有的被扶养人
      let index = 0 // 索引
      // 表示未成年
      if (this.BeiFuYangRenShengHuoFei.params.checked1) {
        let tempIndex = 1
        this.BeiFuYangRenShengHuoFei.params.list1.map((item, i) => {
          const tempY = item.year === '' ? 0 : item.year
          const tempM = item.month === '' ? 0 : item.month
          const year = parseFloat(tempY) + parseFloat(tempM / 12)
          if (year < 18 && item.num) {
            const Y = 18 - year
            if (Y > index) {
              index = Y
            }
            const obj = {
              type: 'list1',
              index: i,
              sum: 0,
              text: `未成年 被扶养人${tempIndex}: 年龄${tempY}岁${tempM}个月，抚养人人数${item.num}人`,
              expenditure: 0,
              year: Y, // 抚养的年份
              num: item.num, // 扶养人
            }
            tempIndex++
            list.push(obj)
          }
        })
      }
      // 表示无劳动力
      if (this.BeiFuYangRenShengHuoFei.params.checked2) {
        let tempIndex = 1
        this.BeiFuYangRenShengHuoFei.params.list2.map((item, i) => {
          const year = 20
          if (year > index) {
            index = year
          }
          if (item.num) {
            const obj = {
              type: 'list2',
              index: i,
              sum: 0,
              expenditure: 0,
              text: `无劳动能力又无其他生活来源的 被扶养人${tempIndex}: 抚养人人数${item.num}人`,
              year: year, // 抚养的年份
              num: item.num, // 扶养人
            }
            tempIndex++
            list.push(obj)
          }
        })
      }
      // 被扶养人无劳动能力又无其他生活来源的,60岁以上
      if (this.BeiFuYangRenShengHuoFei.params.checked3) {
        let tempIndex = 1
        this.BeiFuYangRenShengHuoFei.params.list3.map((item, i) => {
          const tempY = item.year === '' ? 0 : item.year
          const tempM = item.month === '' ? 0 : item.month
          const year = parseFloat(tempY) + parseFloat(tempM / 12)
          if (year > 60 && year < 75 && item.num) {
            const Y = 20 - (year - 60)
            if (Y > index) {
              index = Y
            }
            const obj = {
              type: 'list3',
              index: i,
              sum: 0,
              expenditure: 0,
              text: `60岁以上 被扶养人${tempIndex}: 年龄${tempY}岁${tempM}个月，抚养人人数${item.num}人`,
              year: Y, // 抚养的年份
              num: item.num, // 扶养人
            }
            tempIndex++
            list.push(obj)
          }
        })
      }
      // 被扶养人无劳动能力又无其他生活来源的,75岁以上
      if (this.BeiFuYangRenShengHuoFei.params.checked4) {
        let tempIndex = 1
        this.BeiFuYangRenShengHuoFei.params.list4.map((item, i) => {
          const year = 5
          if (year > index) {
            index = year
          }
          if (item.num) {
            const obj = {
              type: 'list4',
              index: i,
              sum: 0,
              expenditure: 0,
              text: `75岁以上 被扶养人${tempIndex}: 抚养人人数${item.num}人`,
              year: year, // 抚养的年份
              num: item.num, // 扶养人
            }
            tempIndex++
            list.push(obj)
          }
        })
      }
      let expenditure = 0 // 超过抚养年限的钱的总和
      const income = this.BeiFuYangRenShengHuoFei.params.income === '' ? 0 : this.BeiFuYangRenShengHuoFei.params.income
      for (let i = 0; i < index; i++) {
        let sum = 0 // 每一年的总和
        let num = 0 // 每一年的人数
        list.map((item) => {
          if (item.year > i) {
            num++
            const diff = item.year - index
            if (diff < 1 && diff > 0) {
              const z = (income * diff) / item.num
              item.sum += z
              sum += z
            } else {
              const z = (income * 1) / item.num
              item.sum += z
              sum += z
            }
          }
        })
        var x = sum > income ? sum - income : 0 // 每年的总超标额
        var y = x / num // 每年每人的超标额
        list.map((item) => {
          if (item.year > i) {
            item.expenditure += y
          }
        })
        expenditure += sum
        sum = sum > income ? income : sum
        sum = parseFloat(sum)
        result += sum
      }
      list.forEach((item) => {
        // eslint-disable-next-line standard/computed-property-even-spacing
        this.BeiFuYangRenShengHuoFei.params[item.type][item.index].result = parseFloat(parseFloat(item.sum).toFixed(2))
        // eslint-disable-next-line standard/computed-property-even-spacing
        this.BeiFuYangRenShengHuoFei.params[item.type][item.index].expenditure = parseFloat(parseFloat(item.expenditure).toFixed(2))
        text += item.text
        text += `，计${parseFloat(parseFloat(item.sum).toFixed(2))}元`
        if (parseFloat(parseFloat(item.expenditure).toFixed(2)) > 0) {
          text += `(已超标${parseFloat(parseFloat(item.expenditure).toFixed(2))})\n`
        } else {
          text += '\n'
        }
      })
      expenditure = parseFloat(parseFloat(expenditure).toFixed(2))
      result = parseFloat(parseFloat(result).toFixed(2))
      if (expenditure > result) {
        text += '(已超人均年消费支出)'
        this.BeiFuYangRenShengHuoFei.expenditure = expenditure
      } else {
        this.BeiFuYangRenShengHuoFei.expenditure = ''
      }
      // 法条
      if (this.BeiFuYangRenShengHuoFeiChange) {
        result = parseFloat(parseFloat(this.BeiFuYangRenShengHuoFeiResult).toFixed(2))
      } else {
        this.BeiFuYangRenShengHuoFeiResult = result
      }
      if (isNaN(result)) {
        result = 0
      }
      text += `合计${result}元`
      if (this.BeiFuYangRenShengHuoFei.law) {
        law = this.BeiFuYangRenShengHuoFei.law + '<br />'
      }
      return {
        result: result,
        law: law,
        text: text,
      }
    },

    // 被扶养人生活费增加按钮
    BeiFuYangRenShengHuoFeiAdd(index) {
      const list = 'list' + index
      this.BeiFuYangRenShengHuoFei.params[list].push({
        year: '',
        month: '',
        num: '',
      })
    },

    // 被扶养人生活费删除按钮
    BeiFuYangRenShengHuoFeiDel(index, i) {
      const list = 'list' + index
      this.BeiFuYangRenShengHuoFei.params[list].splice(1, 1)
    },

    // 死亡赔偿金设置地址
    handleSelectSiWangPeiChangJin(item) {
      this.SiWangPeiChangJin.params.address = item.provinceCityRegion
      const address = this.SiWangPeiChangJin.params.address.split('/')
      const params2 = {
        currentPage: 1,
        pageSize: 10,
        province: address[0],
        city: address[1],
        district: address[2],
        dataType: '人均可支配收入',
      }
      if (this.time) {
        params2.year = parseInt(this.time) - 1
      }
      this.$axios
        .get(`${this.$base}/admin/peopleData/query`, {
          params: params2,
        })
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.list.length > 0) {
              this.SiWangPeiChangJin.params.income = res.data.data.list[0].dataContent
            } else {
              this.SiWangPeiChangJin.params.income = ''
            }
          }
        })
    },
    upAddressSiWangPeiChangJin(address) {
      this.SiWangPeiChangJin.params.address = address
    },

    // 死亡赔偿金
    getSiWangPeiChangJin() {
      let result = 0
      if (this.age === '' || this.time === '') {
        this.SiWangPeiChangJin.params.year = ''
        if (this.SiWangPeiChangJinChange) {
          result = parseFloat(parseFloat(this.SiWangPeiChangJinResult).toFixed(2))
          if (isNaN(result)) {
            result = 0
          }
          var obj = {
            result: result,
            law: this.SiWangPeiChangJin.law,
            text: `合计${result}元`,
          }
          return obj
        } else {
          return {
            result: 0,
            law: this.SiWangPeiChangJin.law,
            text: '合计0元',
          }
        }
      }
      const address = this.address
      const income = this.SiWangPeiChangJin.params.income === '' ? 0 : parseFloat(this.SiWangPeiChangJin.params.income)
      const income2 = this.SiWangPeiChangJin.params.income2 === '' ? 0 : parseFloat(this.SiWangPeiChangJin.params.income2)
      const coefficient = this.SiWangPeiChangJin.params.coefficient === '' ? 1 : parseFloat(this.SiWangPeiChangJin.params.coefficient)
      let law = ''
      let text = ''
      const time = parseInt(this.time) - 1
      if (this.age < 60) {
        if (this.address === '江苏') {
          result = (income2 + income) * coefficient * 20
          this.SiWangPeiChangJin.params.text = `(${time}年全省居民人均工资性收入${income}元 + 全省居民人均经营净收入${income2}元)*年度全省平均负担系数${coefficient}*20年`
          this.SiWangPeiChangJin.params.year = 20
        } else {
          result = income * 20
          this.SiWangPeiChangJin.params.text = `${time}年人均可支配收入${income}元*20年`
          this.SiWangPeiChangJin.params.year = 20
        }
      } else if (this.age >= 60 && this.age < 75) {
        if (this.address === '江苏') {
          const year = 20 + 60 - this.age
          result = (income2 + income) * coefficient * year
          this.SiWangPeiChangJin.params.text = `(${time}年全省居民人均工资性收入${income}元 + 全省居民人均经营净收入${income2}元)*年度全省平均负担系数${coefficient}*${year}年`
          this.SiWangPeiChangJin.params.year = year
        } else {
          const year = 20 + 60 - this.age
          result = income * year
          this.SiWangPeiChangJin.params.text = `${time}年人均可支配收入${income}元*${year}年`
          this.SiWangPeiChangJin.params.year = year
        }
      } else {
        if (this.address === '江苏') {
          result = (income2 + income) * coefficient * 5
          this.SiWangPeiChangJin.params.text = `(${time}年全省居民人均工资性收入${income}元 + 全省居民人均经营净收入${income2}元)*年度全省平均负担系数${coefficient}*5年`
          this.SiWangPeiChangJin.params.year = 5
        } else {
          const year = 5
          result = income * year
          this.SiWangPeiChangJin.params.text = `${time}年人均可支配收入${income}元*5年`
          this.SiWangPeiChangJin.params.year = 5
        }
      }
      result = parseFloat(parseFloat(result).toFixed(2))
      if (this.SiWangPeiChangJinChange) {
        result = parseFloat(parseFloat(this.SiWangPeiChangJinResult).toFixed(2))
      } else {
        this.SiWangPeiChangJinResult = result
      }
      if (isNaN(result)) {
        result = 0
      }
      if (this.SiWangPeiChangJin.law) {
        law = this.SiWangPeiChangJin.law + '<br />'
      }
      text += `${address}，${this.SiWangPeiChangJin.params.text}  合计${result}元`
      return {
        result: result,
        law: law,
        text: text,
      }
    },
    // 丧葬费设置地址
    handleSelectSangZangFei(item) {
      this.SangZangFei.params.address = item.provinceCityRegion
    },
    upAddressSangZangFei(address) {
      this.SangZangFei.params.address = address
    },
    handleSelectIndustry(item) {
      this.SangZangFei.params.money = item.dataContent
    },
    upIndustry(industry) {
      this.SangZangFei.params.industry = industry
    },
    // 丧葬费
    getSangZangFei() {
      let result = 0
      let law = ''
      let text = ''
      if (this.SangZangFei.params.money) {
        result += (this.SangZangFei.params.money / 12) * 6
      }
      result = parseFloat(parseFloat(result).toFixed(2))
      if (this.SangZangFeiChange) {
        result = parseFloat(parseFloat(this.SangZangFeiResult).toFixed(2))
      } else {
        this.SangZangFeiResult = result
      }
      if (isNaN(result)) {
        result = 0
      }
      if (this.SangZangFei.law) {
        law = this.SangZangFei.law + '<br />'
      }
      text += `职工年平均工资${this.SangZangFei.params.money}/12个月*6个月 合计${result}元`
      return {
        result: result,
        law: law,
        text: text,
      }
    },

    // 获取伤残系数
    getShangCanPercentage() {
      let first = 0
      for (let i = 0; i < this.firstDisabled.length; i++) {
        if (this.firstDisabled[i].label === this.ShangCanPeiChang.params.firstDisabled) {
          first = this.firstDisabled[i].value
        }
      }
      let second = 0
      for (let i = 0; i < this.secondDisabled.length; i++) {
        for (let j = 0; j < this.ShangCanPeiChang.params.secondDisabled.length; j++) {
          if (this.secondDisabled[i].label === this.ShangCanPeiChang.params.secondDisabled[j].label) {
            second += this.secondDisabled[i].value
          }
        }
      }
      if (second > 0.1) {
        second = 0.1
      }
      if (second + first > 1) {
        second = 1 - first
      }
      return {
        second: second,
        first: first,
      }
    },
    // 伤残赔偿
    getShangCanPeiChang() {
      if (this.age === '' || this.time === '') {
        return {
          result: 0,
          law: '',
        }
      }
      const income = this.ShangCanPeiChang.params.income === '' ? 0 : this.ShangCanPeiChang.params.income
      let result = 0
      let law = ''
      let text = ''
      if (this.age < 60) {
        this.ShangCanPeiChang.params.year = 20
      } else if (this.age >= 60 && this.age < 75) {
        const year = 20 + 60 - this.age
        this.ShangCanPeiChang.params.year = year
      } else {
        this.ShangCanPeiChang.params.year = 5
      }
      const percentage = this.getShangCanPercentage()
      result = income * this.ShangCanPeiChang.params.year * (percentage.first + percentage.second)
      result = parseFloat(parseFloat(result).toFixed(2))
      this.ShangCanPeiChang.result = result
      if (this.ShangCanPeiChang.law) {
        law = this.ShangCanPeiChang.law + '<br />'
      }
      const time = parseInt(this.time) - 1
      text += `${time}年${this.address}人均可支配收入${this.ShangCanPeiChang.params.income}元*(${percentage.first * 100}%+${percentage.second * 100}%)*${
        this.ShangCanPeiChang.params.year
      }年 合计${result}元`
      return {
        result: result,
        law: law,
        text: text,
      }
    },
    ShangCanPeiChangIncomeBlur() {
      this.addIncome(this.keyboard)
      if (this.ShangCanPeiChang.params.income === '') {
        this.ShangCanPeiChang.params.income = 0
      }
      this.ShangCanPeiChangIncomeInputShow = false
    },
    // 获取伤残等级比例
    getDisabledPercentage(type, label) {
      if (label === '') {
        return ''
      }
      let result = ''
      if (type === 'first') {
        for (let i = 0; i < this.firstDisabled.length; i++) {
          if (this.firstDisabled[i].label === label) {
            result = this.firstDisabled[i].percentage
          }
        }
      } else if (type === 'second') {
        for (let i = 0; i < this.secondDisabled.length; i++) {
          if (this.secondDisabled[i].label === label) {
            result = this.secondDisabled[i].percentage
          }
        }
      }
      return result
    },
    // 最高伤残等级改变
    firstDisabledChange(value) {
      console.log(value)
      let index = 0
      for (let i = 0; i < this.firstDisabled.length; i++) {
        if (this.firstDisabled[i].label === value) {
          index = i
        }
      }
      if (index === 0) {
        index = 0
      } else {
        index = index - 1
      }
      this.secondDisabledCopy = this.secondDisabled.slice(index - 1)
    },
    // 增加伤残等级
    secondDisabledChange(value) {
      console.log(value)
      if (this.secondDisabledType === -1) {
        if (value === '') {
          return
        }
        let index = 0
        for (let i = 0; i < this.secondDisabled.length; i++) {
          if (this.secondDisabled[i].label === value) {
            index = i
          }
        }
        this.ShangCanPeiChang.params.secondDisabled.push({
          label: this.secondDisabled[index].label,
        })
        this.secondDisabledValue = ''
      } else {
        const index = this.secondDisabledType
        this.ShangCanPeiChang.params.secondDisabled[index].label = value
      }
    },
    onClickFirstDisabled(option, index) {
      this.$refs.checkboxes[index].toggle()
      this.firstDisabledShow = false
    },
    onClickSecondDisabled(option, index) {
      this.$refs.checkboxesSecond[index].toggle()
      this.secondDisabledShow = false
    },
    addSecondDisabled(type, label = '') {
      this.secondDisabledValue = label
      this.secondDisabledType = type
      this.secondDisabledShow = true
    },

    // 全选框改变
    allCheckedChange() {
      this.selectAll(this.allChecked)
    },
    // 全选
    selectAll(flag) {
      this.SiWangPeiChangJin.checked = flag
      this.SangZangFei.checked = flag
      this.YiLiaoFei.checked = flag
      this.JiaoTongFei.checked = flag
      this.ZhuSuFei.checked = flag
      this.JingShenSunHaiFuWeiJin.checked = flag
      this.WuGongFei.checked = flag
      this.ZhuYuanHuLiFei.checked = flag
      this.ChuYuanHuLiFei.checked = flag
      this.BeiFuYangRenShengHuoFei.checked = flag
      this.BeiFuYangRenShengHuoFei.checked = flag
      // this.ShangCanPeiChang.checked = flag
    },

    // 判断是否全选
    isSelectAll() {
      if (
        this.SiWangPeiChangJin.checked === true &&
        this.SangZangFei.checked === true &&
        this.YiLiaoFei.checked === true &&
        this.JiaoTongFei.checked === true &&
        this.ZhuSuFei.checked === true &&
        this.JingShenSunHaiFuWeiJin.checked === true &&
        this.WuGongFei.checked === true &&
        this.ZhuYuanHuLiFei.checked === true &&
        this.ChuYuanHuLiFei.checked === true &&
        this.BeiFuYangRenShengHuoFei.checked === true
        // this.ShangCanPeiChang.checked === true
      ) {
        this.allChecked = true
      } else {
        this.allChecked = false
      }
    },

    // 处理导出和复制的文字
    handleText() {
      let text = ''
      text += '                    死亡赔偿金\n'
      text += `基本信息：死亡时年龄${this.resultObj.age}岁，死亡年份${this.resultObj.time}年，${this.resultObj.address}\n`
      text += '赔偿项目：\n'
      text += this.resultObj.text
      text += `总计：${this.resultObj.result}元\n`
      text += '法条依据：\n'
      const key = '<br />'
      const law = this.resultObj.law.replace(new RegExp(key, 'g'), '\n')
      text += law
      return text
    },

    // 点击复制
    async copyAll() {
      let text = this.handleText()
      let url = window.location.href
      const res = await longUrl2ShortUrl(url)
      if (res.status === 200) {
        url = res.data
        text += '\n' + url
      }
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
      this.addHistory()
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    // 点击下载
    downLoad() {
      // const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', time)
      const text = this.handleText()
      var blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
      const file = new File([blob], '计算结果.txt', {
        type: 'text/plain;charset=utf-8',
      })
      const qiniu = new this.$qiniu()
      qiniu.UploadFile(
        'DEFAULT',
        file,
        {
          next: (file) => {},
          complete: () => {},
          error: () => {
            this.$toast('上传失败，上传终止')
          },
        },
        (fileopt, key, file) => {
          // 拼接完整的图片的地址
          const url = window.location.protocol + qiniuBase + '/' + key
          const name = url.substring(url.lastIndexOf('/') + 1)
          // const pathUrl = url.substring(0, url.lastIndexOf('/')) // 截取图片的路径
          const ua = navigator.userAgent
          if (ua === this.$ua.a) {
            // eslint-disable-next-line
            // AND2JS.showFilePreview(url)
            window.showFilePreview.postMessage(
              JSON.stringify({
                filePath: url,
                fileName: name,
              })
            )
          } else if (ua === 'fb-flutter-Webview') {
            // flutter环境
            // eslint-disable-next-line
            try {
              // 之前写的方法
              // window.fbDownloadFile.postMessage(url)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: url,
                  fileName: name,
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.i) {
            this.iphone = true
            // ios环境
            // eslint-disable-next-line
            // window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
          } else {
            // window.location = url
            console.log('下载地址:', url)
          }
        }
      )
      this.addHistory()
    },

    // 点击分享
    async share() {
      let text = window.location.href
      const obj = {
        isShare: true,
      }
      text += `?data=${JSON.stringify(obj)}`
      const res = await longUrl2ShortUrl(text)
      if (res.status === 200) {
        text = res.data
      }
      // console.log(window.encodeURI(text))
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes('死亡损害赔偿金', '律呗死亡损害赔偿金计算工具', window.encodeURI(text))
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: '死亡损害赔偿金',
            url: window.encodeURI(text),
          })
        )
      } else {
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已复制本网页地址，请粘贴到QQ或微信中',
        })
      }
      this.addHistory()
    },

    // 点击反馈
    feedback() {
      this.$router.push({
        name: 'feedback',
      })
    },
  },
}
