<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="死亡损害赔偿金" fixed>
      <fb-button v-if="!isShare" slot="left" icon="back" @click="backToNative()" size="small" type="primary"> 返回 </fb-button>
      <div slot="right">
        <tool-head type="deathCompensation" @clickItem="clickItem">
          <div style="display: flex; align-items: center">
            <span style="margin-right: 10px" @click="reset">还原</span>
            <font-size-comp @setFontSize="setFontSize" ref="fontSizeComp" />
          </div>
        </tool-head>
      </div>
    </fb-header>
    <div id="tools" class="tools-deathCompensation" style="font-size: 14px !important" :style="createCaseWrapperStyle">
      <div class="interest-content">
        <!-- <div style="color:#CCCCCC;padding-bottom:11px;">
          基本信息
        </div> -->
        <div class="box">
          <div class="box-left" style="">
            <span class="box-title">死亡年龄</span>
            <van-field
              v-model="age"
              readonly
              clickable
              @touchstart.stop="
                resultInputFlag = false
                show = true
                extraKey = ''
                keyboard = 'age'
              "
              placeholder="请输入"
              :class="keyboard === 'age' && show ? 'van-number-keyboard-input-select' : ''"
            />
            <span class="box-title">岁</span>
          </div>
          <div class="box-right">
            <span class="box-title"></span>
            <van-field v-model="time" placeholder="死亡时间" readonly clickable @click="startTimeShow = true" />
            <span class="box-title">年</span>
          </div>
          <div class="box" style="flex: 1; margin-left: 20px">
            <address-selector :address="this.address" @handleSelect="handleSelect" @upAddress="upAddress" />
          </div>
        </div>

        <div class="box" style="padding: 20px 0 11px 0">
          <div class="box-left">
            <span style="color: #cccccc">项目</span>
            <van-checkbox v-model="allChecked" @click="allCheckedChange" shape="square">全选</van-checkbox>
          </div>
          <div class="box-right">
            <div style="display: flex">
              <van-checkbox v-model="insurance" shape="square">交强险责任分担</van-checkbox>
            </div>
          </div>
        </div>
        <div class="check-group" style="border-bottom: 1px solid #ccc">
          <!-- 死亡赔偿金 -->
          <van-checkbox v-model="SiWangPeiChangJin.checked" shape="square">{{ SiWangPeiChangJin.text }}</van-checkbox>
          <!-- 丧葬费 -->
          <van-checkbox v-model="SangZangFei.checked" shape="square">{{ SangZangFei.text }}</van-checkbox>
          <!-- 被扶养人生活费 -->
          <van-checkbox v-model="BeiFuYangRenShengHuoFei.checked" shape="square">{{ BeiFuYangRenShengHuoFei.text }}</van-checkbox>
          <!-- 医疗费 -->
          <van-checkbox v-model="YiLiaoFei.checked" shape="square">{{ YiLiaoFei.text }}</van-checkbox>
          <!-- 交通费 -->
          <van-checkbox v-model="JiaoTongFei.checked" shape="square">{{ JiaoTongFei.text }}</van-checkbox>
          <!-- 住宿费 -->
          <van-checkbox v-model="ZhuSuFei.checked" shape="square">{{ ZhuSuFei.text }}</van-checkbox>
          <!-- 精神损害抚慰金 -->
          <van-checkbox v-model="JingShenSunHaiFuWeiJin.checked" shape="square">{{ JingShenSunHaiFuWeiJin.text }}</van-checkbox>
          <!-- 误工费 -->
          <van-checkbox v-model="WuGongFei.checked" shape="square">{{ WuGongFei.text }}</van-checkbox>
          <!-- 住院护理费 -->
          <van-checkbox v-model="ZhuYuanHuLiFei.checked" shape="square">{{ ZhuYuanHuLiFei.text }}</van-checkbox>
          <!-- 出院护理费 -->
          <van-checkbox v-model="ChuYuanHuLiFei.checked" shape="square">{{ ChuYuanHuLiFei.text }}</van-checkbox>
        </div>
      </div>
      <!-- 死亡赔偿金 -->
      <div class="project" v-show="SiWangPeiChangJin.checked">
        <div class="project-title">
          <div class="project-title-name">{{ SiWangPeiChangJin.text }}</div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'SiWangPeiChangJinResult'
                setZero('SiWangPeiChangJinResult')
              "
              :class="keyboard === 'SiWangPeiChangJinResult' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="SiWangPeiChangJinResult !== ''">
                <span v-show="yuanToWan(SiWangPeiChangJinResult).wan > 0">
                  {{ yuanToWan(SiWangPeiChangJinResult).wan }}
                </span>
                <span v-show="yuanToWan(SiWangPeiChangJinResult).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(SiWangPeiChangJinResult).yuan }}</span>
              </span>
            </div>
            元
          </div>
          <div @click="SiWangPeiChangJinShow = !SiWangPeiChangJinShow">
            <van-icon v-show="SiWangPeiChangJinShow === false" name="arrow" />
            <van-icon v-show="SiWangPeiChangJinShow === true" name="arrow-down" />
          </div>
        </div>
        <div class="project-content" v-show="SiWangPeiChangJinShow === true">
          <div class="box" style="padding: 10px 0">
            <span v-if="address === '江苏'"
              >(
              <!-- 人均可支配收入
              <input
                class="table-input"
                v-model="SiWangPeiChangJin.params.income"
                @change="addIncome('SiWangPeiChangJin')"
                clickable
                readonly
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'SiWangPeiChangJin.params.income'
                "
                :class="
                  keyboard === 'SiWangPeiChangJin.params.income' && show
                    ? 'van-number-keyboard-input-select'
                    : ''
                "
              /> -->
              <span style="color: #0099ff; cursor: pointer" @click="openErrorCorrectionDialog('全省居民人均工资性收入', '元', SiWangPeiChangJin.params.income)"
                >{{ SiWangPeiChangJin.params.income }}元</span
              >
              +
              <span style="color: #0099ff; cursor: pointer" @click="openErrorCorrectionDialog('全省居民人均经营净收入', '元', SiWangPeiChangJin.params.income2)"
                >{{ SiWangPeiChangJin.params.income2 }}元</span
              >)*<span @click="openErrorCorrectionDialog('全省平均负担系数', '元', SiWangPeiChangJin.params.coefficient)" style="color: #0099ff; cursor: pointer"
                >{{ SiWangPeiChangJin.params.coefficient }} </span
              >*{{ SiWangPeiChangJin.params.year }}年</span
            >
            <span v-if="address !== '江苏'">
              <!-- 人均可支配收入 -->
              <!-- <input
                class="table-input"
                v-model="SiWangPeiChangJin.params.income"
                @change="addIncome('SiWangPeiChangJin')"
                clickable
                readonly
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'SiWangPeiChangJin.params.income'
                "
                :class="
                  keyboard === 'SiWangPeiChangJin.params.income' && show
                    ? 'van-number-keyboard-input-select'
                    : ''
                "
              /> -->
              <span style="color: #0099ff; cursor: pointer" @click="openErrorCorrectionDialog('人均可支配收入', '元', SiWangPeiChangJin.params.income)">{{ SiWangPeiChangJin.params.income }}元</span>
              *{{ SiWangPeiChangJin.params.year }}年</span
            >
          </div>
        </div>
      </div>

      <!-- 丧葬费 -->
      <div class="project" v-show="SangZangFei.checked">
        <div class="project-title">
          <div class="project-title-name">{{ SangZangFei.text }}</div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'SangZangFeiResult'
                setZero('SangZangFeiResult')
              "
              :class="keyboard === 'SangZangFeiResult' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="SangZangFeiResult !== ''">
                <span v-show="yuanToWan(SangZangFeiResult).wan > 0">
                  {{ yuanToWan(SangZangFeiResult).wan }}
                </span>
                <span v-show="yuanToWan(SangZangFeiResult).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(SangZangFeiResult).yuan }}</span>
              </span>
            </div>
            <!-- <input
              class="table-input-ji"
              v-model="SangZangFeiResult"
              clickable
              readonly
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'SangZangFeiResult'
                setZero('SangZangFeiResult')
              "
              :class="
                keyboard === 'SangZangFeiResult' && show
                  ? 'van-number-keyboard-input-select'
                  : ''
              "
            /> -->
            元
          </div>
          <div @click="SangZangFeiShow = !SangZangFeiShow">
            <van-icon v-show="SangZangFeiShow === false" name="arrow" />
            <van-icon v-show="SangZangFeiShow === true" name="arrow-down" />
          </div>
        </div>
        <div class="project-content" v-show="SangZangFeiShow === true">
          <div class="box">
            <!-- <div style="flex: 1">
              <address-selector
                :address="SangZangFei.params.address"
                @handleSelect="handleSelectSangZangFei"
                @upAddress="upAddressSangZangFei"
              />
            </div>
            <div style="width: 100px">
              <van-field
                v-model="SangZangFei.params.time"
                placeholder="死亡年份"
              />
            </div> -->
            <div style="flex: 1">
              <industry-selector
                :address="SangZangFei.params.address"
                :industry="SangZangFei.params.industry"
                :time="SangZangFei.params.time"
                @upIndustry="upIndustry"
                @handleSelectIndustry="handleSelectIndustry"
              />
            </div>
          </div>
          <div class="box" style="padding: 10px 0">
            <span
              >职工年平均工资<input
                class="table-input"
                v-model="SangZangFei.params.money"
                @change="addIncome('SangZangFei')"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'SangZangFei.params.money'
                "
                :class="keyboard === 'SangZangFei.params.money' && show ? 'van-number-keyboard-input-select' : ''"
              />元/12个月*6个月</span
            >
          </div>
        </div>
      </div>

      <!-- 被抚养人生活费 -->
      <div class="project" v-show="BeiFuYangRenShengHuoFei.checked">
        <div class="project-title">
          <div class="project-title-name">
            {{ BeiFuYangRenShengHuoFei.text }}
          </div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'BeiFuYangRenShengHuoFeiResult'
                setZero('BeiFuYangRenShengHuoFeiResult')
              "
              :class="keyboard === 'BeiFuYangRenShengHuoFeiResult' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="BeiFuYangRenShengHuoFeiResult !== ''">
                <span v-show="yuanToWan(BeiFuYangRenShengHuoFeiResult).wan > 0">
                  {{ yuanToWan(BeiFuYangRenShengHuoFeiResult).wan }}
                </span>
                <span v-show="yuanToWan(BeiFuYangRenShengHuoFeiResult).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(BeiFuYangRenShengHuoFeiResult).yuan }}</span>
              </span>
            </div>
            <!-- <input
              class="table-input-ji"
              v-model="BeiFuYangRenShengHuoFeiResult"
              clickable
              readonly
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'BeiFuYangRenShengHuoFeiResult'
                setZero('BeiFuYangRenShengHuoFeiResult')
              "
              :class="
                keyboard === 'BeiFuYangRenShengHuoFeiResult' && show
                  ? 'van-number-keyboard-input-select'
                  : ''
              "
            /> -->
            元
            <span v-show="BeiFuYangRenShengHuoFei.expenditure" style="color: #ff9900">(={{ BeiFuYangRenShengHuoFei.expenditure }}元超出人均年消费支出)</span>
          </div>
          <div @click="BeiFuYangRenShengHuoFeiShow = !BeiFuYangRenShengHuoFeiShow">
            <van-icon v-show="BeiFuYangRenShengHuoFeiShow === false" name="arrow" />
            <van-icon v-show="BeiFuYangRenShengHuoFeiShow === true" name="arrow-down" />
          </div>
        </div>
        <div class="project-content" v-show="BeiFuYangRenShengHuoFeiShow === true">
          <!-- 未成年 -->
          <div class="box2" style="padding: 10px 0">
            <div>
              <i class="iconfont icon-xuanzhong" v-show="BeiFuYangRenShengHuoFei.params.checked1" @click="BeiFuYangRenShengHuoFei.params.checked1 = false"></i>
              <i class="iconfont icon-kongbai" v-show="BeiFuYangRenShengHuoFei.params.checked1 === false" @click="BeiFuYangRenShengHuoFei.params.checked1 = true"></i>
              未成年
            </div>
            <div style="flex: 1; margin-left: 10px">
              <div v-for="(item, i) in BeiFuYangRenShengHuoFei.params.list1" :key="i">
                被抚养人{{ i + 1 }}：年龄<input
                  class="table-input table-input-year"
                  v-model="item.year"
                  readonly
                  clickable
                  @touchstart.stop="
                    resultInputFlag = false
                    show = true
                    extraKey = ''
                    keyboard = `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.year'}`
                    titleObj = {
                      key: `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.year'}`,
                      no: '请输入被抚养人年龄',
                      yes: '被抚养人年龄###岁',
                    }
                    setKeyboardTitle(item.year, `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.year'}`)
                  "
                  :class="keyboard === `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.year'}` && show ? 'van-number-keyboard-input-select' : ''"
                />岁<input
                  class="table-input table-input-year"
                  v-model="item.month"
                  readonly
                  clickable
                  @touchstart.stop="
                    resultInputFlag = false
                    show = true
                    extraKey = ''
                    keyboard = `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.month'}`
                    titleObj = {
                      key: `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.month'}`,
                      no: '请输入被抚养人年龄',
                      yes: '###个月',
                    }
                    setKeyboardTitle(item.month, `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.month'}`)
                  "
                  :class="keyboard === `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.month'}` && show ? 'van-number-keyboard-input-select' : ''"
                />个月，抚养人人数<input
                  class="table-input table-input-num"
                  v-model="item.num"
                  readonly
                  clickable
                  @touchstart.stop="
                    resultInputFlag = false
                    show = true
                    extraKey = ''
                    keyboard = `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.num'}`
                    titleObj = {
                      key: `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.num'}`,
                      no: '请输入抚养人人数',
                      yes: '抚养人人数###人',
                    }
                    setKeyboardTitle(item.num, `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.num'}`)
                  "
                  :class="keyboard === `${'BeiFuYangRenShengHuoFei.params.list1.' + i + '.num'}` && show ? 'van-number-keyboard-input-select' : ''"
                />人
                <span v-if="item.result">
                  ，计<span style="color: #ed3f3e">{{ item.result }}</span
                  >元<span v-if="item.expenditure">(超标{{ item.expenditure }})</span>
                </span>
                <i v-if="i === 0" style="color: #569af9; cursor: pointer; margin-left: 20px" class="iconfont icon-tianjia1" @click="BeiFuYangRenShengHuoFeiAdd(1)"></i>
                <i v-if="i > 0" style="color: #569af9; cursor: pointer; margin-left: 20px" class="iconfont icon-shanchu1" @click="BeiFuYangRenShengHuoFeiDel(1, i)"></i>
              </div>
            </div>
          </div>
          <!-- 无劳动能力又无其他生活来源的 -->
          <div class="box2" style="padding: 10px 0">
            <div style="display: flex">
              <i class="iconfont icon-xuanzhong" v-show="BeiFuYangRenShengHuoFei.params.checked2" @click="BeiFuYangRenShengHuoFei.params.checked2 = false"></i>
              <i class="iconfont icon-kongbai" v-show="BeiFuYangRenShengHuoFei.params.checked2 === false" @click="BeiFuYangRenShengHuoFei.params.checked2 = true"></i>
              <div>
                <div>无劳动能力又无</div>
                <div>其他生活来源的</div>
              </div>
            </div>
            <div style="flex: 1; margin-left: 10px">
              <div v-for="(item, i) in BeiFuYangRenShengHuoFei.params.list2" :key="i">
                被抚养人{{ i + 1 }}：抚养人人数<input
                  class="table-input table-input-num"
                  v-model="item.num"
                  readonly
                  clickable
                  @touchstart.stop="
                    resultInputFlag = false
                    show = true
                    extraKey = ''
                    keyboard = `${'BeiFuYangRenShengHuoFei.params.list2.' + i + '.num'}`
                    titleObj = {
                      key: `${'BeiFuYangRenShengHuoFei.params.list2.' + i + '.num'}`,
                      no: '请输入抚养人人数',
                      yes: '抚养人人数###人',
                    }
                    setKeyboardTitle(item.num, `${'BeiFuYangRenShengHuoFei.params.list2.' + i + '.num'}`)
                  "
                  :class="keyboard === `${'BeiFuYangRenShengHuoFei.params.list2.' + i + '.num'}` && show ? 'van-number-keyboard-input-select' : ''"
                />人
                <span v-if="item.result">
                  ，计<span style="color: #ed3f3e">{{ item.result }}</span
                  >元<span v-if="item.expenditure">(超标{{ item.expenditure }})</span>
                </span>
                <i v-if="i === 0" style="color: #569af9; cursor: pointer; margin-left: 20px" class="iconfont icon-tianjia1" @click="BeiFuYangRenShengHuoFeiAdd(2)"></i>
                <i v-if="i > 0" style="color: #569af9; cursor: pointer; margin-left: 20px" class="iconfont icon-shanchu1" @click="BeiFuYangRenShengHuoFeiDel(2, i)"></i>
              </div>
            </div>
          </div>
          <!-- 60岁以上 -->
          <div class="box2" style="padding: 10px 0">
            <div>
              <i class="iconfont icon-xuanzhong" v-show="BeiFuYangRenShengHuoFei.params.checked3" @click="BeiFuYangRenShengHuoFei.params.checked3 = false"></i>
              <i class="iconfont icon-kongbai" v-show="BeiFuYangRenShengHuoFei.params.checked3 === false" @click="BeiFuYangRenShengHuoFei.params.checked3 = true"></i>
              60岁以上
            </div>
            <div style="flex: 1; margin-left: 10px">
              <div v-for="(item, i) in BeiFuYangRenShengHuoFei.params.list3" :key="i">
                被抚养人{{ i + 1 }}：年龄<input
                  class="table-input table-input-year"
                  v-model="item.year"
                  readonly
                  clickable
                  @touchstart.stop="
                    resultInputFlag = false
                    show = true
                    extraKey = ''
                    keyboard = `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.year'}`
                    titleObj = {
                      key: `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.year'}`,
                      no: '请输入被抚养人年龄',
                      yes: '被抚养人年龄###岁',
                    }
                    setKeyboardTitle(item.year, `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.year'}`)
                  "
                  :class="keyboard === `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.year'}` && show ? 'van-number-keyboard-input-select' : ''"
                />岁<input
                  class="table-input table-input-year"
                  v-model="item.month"
                  readonly
                  clickable
                  @touchstart.stop="
                    resultInputFlag = false
                    show = true
                    extraKey = ''
                    keyboard = `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.month'}`
                    titleObj = {
                      key: `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.month'}`,
                      no: '请输入被抚养人年龄',
                      yes: '###个月',
                    }
                    setKeyboardTitle(item.month, `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.month'}`)
                  "
                  :class="keyboard === `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.month'}` && show ? 'van-number-keyboard-input-select' : ''"
                />个月，抚养人人数<input
                  class="table-input table-input-num"
                  v-model="item.num"
                  readonly
                  clickable
                  @touchstart.stop="
                    resultInputFlag = false
                    show = true
                    extraKey = ''
                    keyboard = `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.num'}`
                    titleObj = {
                      key: `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.num'}`,
                      no: '请输入抚养人人数',
                      yes: '抚养人人数###人',
                    }
                    setKeyboardTitle(item.num, `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.num'}`)
                  "
                  :class="keyboard === `${'BeiFuYangRenShengHuoFei.params.list3.' + i + '.num'}` && show ? 'van-number-keyboard-input-select' : ''"
                />人
                <span v-if="item.result">
                  ，计<span style="color: #ed3f3e">{{ item.result }}</span
                  >元<span v-if="item.expenditure">(超标{{ item.expenditure }})</span>
                </span>
                <i v-if="i === 0" style="color: #569af9; cursor: pointer; margin-left: 20px" class="iconfont icon-tianjia1" @click="BeiFuYangRenShengHuoFeiAdd(3)"></i>
                <i v-if="i > 0" style="color: #569af9; cursor: pointer; margin-left: 20px" class="iconfont icon-shanchu1" @click="BeiFuYangRenShengHuoFeiDel(3, i)"></i>
              </div>
            </div>
          </div>
          <!-- 75岁以上 -->
          <div class="box2" style="padding: 10px 0">
            <div>
              <i class="iconfont icon-xuanzhong" v-show="BeiFuYangRenShengHuoFei.params.checked4" @click="BeiFuYangRenShengHuoFei.params.checked4 = false"></i>
              <i class="iconfont icon-kongbai" v-show="BeiFuYangRenShengHuoFei.params.checked4 === false" @click="BeiFuYangRenShengHuoFei.params.checked4 = true"></i>
              75岁以上
            </div>
            <div style="flex: 1; margin-left: 10px">
              <div v-for="(item, i) in BeiFuYangRenShengHuoFei.params.list4" :key="i">
                被抚养人{{ i + 1 }}：抚养人人数<input
                  class="table-input table-input-num"
                  v-model="item.num"
                  readonly
                  clickable
                  @touchstart.stop="
                    resultInputFlag = false
                    show = true
                    extraKey = ''
                    keyboard = `${'BeiFuYangRenShengHuoFei.params.list4.' + i + '.num'}`
                    titleObj = {
                      key: `${'BeiFuYangRenShengHuoFei.params.list4.' + i + '.num'}`,
                      no: '请输入抚养人人数',
                      yes: '抚养人人数###人',
                    }
                    setKeyboardTitle(item.num, `${'BeiFuYangRenShengHuoFei.params.list4.' + i + '.num'}`)
                  "
                  :class="keyboard === `${'BeiFuYangRenShengHuoFei.params.list4.' + i + '.num'}` && show ? 'van-number-keyboard-input-select' : ''"
                />人
                <span v-if="item.result">
                  ，计<span style="color: #ed3f3e">{{ item.result }}</span
                  >元<span v-if="item.expenditure">(超标{{ item.expenditure }})</span>
                </span>
                <i v-if="i === 0" style="color: #569af9; cursor: pointer; margin-left: 20px" class="iconfont icon-tianjia1" @click="BeiFuYangRenShengHuoFeiAdd(4)"></i>
                <i v-if="i > 0" style="color: #569af9; cursor: pointer; margin-left: 20px" class="iconfont icon-shanchu1" @click="BeiFuYangRenShengHuoFeiDel(4, i)"></i>
              </div>
            </div>
          </div>
          <!-- <div class="box2">
            <address-selector
              :address="BeiFuYangRenShengHuoFei.params.address"
              @handleSelect="handleSelectBeiFuYangRenShengHuoFei"
              @upAddress="upAddressBeiFuYangRenShengHuoFei"
            />
          </div> -->
          <div class="box2" style="padding: 10px 0; align-items: center">
            <div>
              <span class="box-title" style="color: #999" v-show="time !== ''">{{ parseInt(time) - 1 }}</span>
              <span style="color: #999">{{ address }}</span>
            </div>
            <!-- <div style="width: 80px" class="beifuyangrenshenghuofei-address">
              <address-selector
                :address="BeiFuYangRenShengHuoFei.params.address"
                @handleSelect="handleSelectBeiFuYangRenShengHuoFei"
                @upAddress="upAddressBeiFuYangRenShengHuoFei"
              />
            </div> -->
            <div style="display: flex; align-items: center">
              <span style="color: #999">人均年消费性支出</span>
              <input
                class="table-input"
                style="height: 42px"
                v-model="BeiFuYangRenShengHuoFei.params.income"
                @change="addIncome('BeiFuYangRenShengHuoFei')"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'BeiFuYangRenShengHuoFei.params.income'
                  setZero('BeiFuYangRenShengHuoFei.params.income')
                "
                :class="keyboard === 'BeiFuYangRenShengHuoFei.params.income' && show ? 'van-number-keyboard-input-select' : ''"
              />
              元
            </div>
          </div>
        </div>
      </div>

      <!-- 医疗费 -->
      <div class="project" v-show="YiLiaoFei.checked">
        <div class="project-title" @click="YiLiaoFei.show = !YiLiaoFei.show">
          <div class="project-title-name">{{ YiLiaoFei.text }}</div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'YiLiaoFei.params.money'
                setZero('YiLiaoFei.params.money')
              "
              :class="keyboard === 'YiLiaoFei.params.money' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="YiLiaoFei.params.money != 0">
                <span v-show="yuanToWan(YiLiaoFei.params.money).wan > 0">
                  {{ yuanToWan(YiLiaoFei.params.money).wan }}
                </span>
                <span v-show="yuanToWan(YiLiaoFei.params.money).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(YiLiaoFei.params.money).yuan }}</span>
              </span>
            </div>
            <!-- <input
              class="table-input-ji"
              v-model="YiLiaoFei.params.money"
              readonly
              clickable
              @touchstart.stop="
                resultInputFlag = false
                show = true
                extraKey = '.'
                keyboard = 'YiLiaoFei.params.money'
                YiLiaoFei.params.money == 0 ? (YiLiaoFei.params.money = '') : ''
              "
              :class="
                keyboard === 'YiLiaoFei.params.money' && show
                  ? 'van-number-keyboard-input-select'
                  : ''
              "
            /> -->
            元
          </div>
          <div></div>
        </div>
      </div>

      <!-- 交通费 -->
      <div class="project" v-show="JiaoTongFei.checked">
        <div class="project-title" @click="JiaoTongFei.show = !JiaoTongFei.show">
          <div class="project-title-name">{{ JiaoTongFei.text }}</div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'JiaoTongFei.params.money'
                setZero('JiaoTongFei.params.money')
              "
              :class="keyboard === 'JiaoTongFei.params.money' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="JiaoTongFei.params.money != 0">
                <span v-show="yuanToWan(JiaoTongFei.params.money).wan > 0">
                  {{ yuanToWan(JiaoTongFei.params.money).wan }}
                </span>
                <span v-show="yuanToWan(JiaoTongFei.params.money).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(JiaoTongFei.params.money).yuan }}</span>
              </span>
            </div>
            <!-- <input
              class="table-input-ji"
              v-model="JiaoTongFei.params.money"
              readonly
              clickable
              @touchstart.stop="
                resultInputFlag = false
                show = true
                extraKey = '.'
                keyboard = 'JiaoTongFei.params.money'
                JiaoTongFei.params.money == 0
                  ? (JiaoTongFei.params.money = '')
                  : ''
              "
              :class="
                keyboard === 'JiaoTongFei.params.money' && show
                  ? 'van-number-keyboard-input-select'
                  : ''
              "
            /> -->
            元
          </div>
          <div></div>
        </div>
      </div>

      <!-- 住宿费 -->
      <div class="project" v-show="ZhuSuFei.checked">
        <div class="project-title" @click="ZhuSuFei.show = !ZhuSuFei.show">
          <div class="project-title-name">{{ ZhuSuFei.text }}</div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'ZhuSuFei.params.money'
                setZero('ZhuSuFei.params.money')
              "
              :class="keyboard === 'ZhuSuFei.params.money' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="ZhuSuFei.params.money != 0">
                <span v-show="yuanToWan(ZhuSuFei.params.money).wan > 0">
                  {{ yuanToWan(ZhuSuFei.params.money).wan }}
                </span>
                <span v-show="yuanToWan(ZhuSuFei.params.money).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(ZhuSuFei.params.money).yuan }}</span>
              </span>
            </div>
            <!-- <input
              class="table-input-ji"
              v-model="ZhuSuFei.params.money"
              readonly
              clickable
              @touchstart.stop="
                resultInputFlag = false
                show = true
                extraKey = '.'
                keyboard = 'ZhuSuFei.params.money'
                ZhuSuFei.params.money == 0 ? (ZhuSuFei.params.money = '') : ''
              "
              :class="
                keyboard === 'ZhuSuFei.params.money' && show
                  ? 'van-number-keyboard-input-select'
                  : ''
              "
            /> -->
            元
          </div>
          <div></div>
        </div>
      </div>

      <!-- 精神损害抚慰金 -->
      <div class="project" v-show="JingShenSunHaiFuWeiJin.checked">
        <div class="project-title" @click="JingShenSunHaiFuWeiJin.show = !JingShenSunHaiFuWeiJin.show">
          <div class="project-title-name">
            {{ JingShenSunHaiFuWeiJin.text }}
          </div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'JingShenSunHaiFuWeiJin.params.money'
                setZero('JingShenSunHaiFuWeiJin.params.money')
              "
              :class="keyboard === 'JingShenSunHaiFuWeiJin.params.money' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="JingShenSunHaiFuWeiJin.params.money != 0">
                <span v-show="yuanToWan(JingShenSunHaiFuWeiJin.params.money).wan > 0">
                  {{ yuanToWan(JingShenSunHaiFuWeiJin.params.money).wan }}
                </span>
                <span v-show="yuanToWan(JingShenSunHaiFuWeiJin.params.money).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(JingShenSunHaiFuWeiJin.params.money).yuan }}</span>
              </span>
            </div>
            <!-- <input
              class="table-input-ji"
              v-model="JingShenSunHaiFuWeiJin.params.money"
              readonly
              clickable
              @touchstart.stop="
                resultInputFlag = false
                show = true
                extraKey = '.'
                keyboard = 'JingShenSunHaiFuWeiJin.params.money'
                JingShenSunHaiFuWeiJin.params.money == 0
                  ? (JingShenSunHaiFuWeiJin.params.money = '')
                  : ''
              "
              :class="
                keyboard === 'JingShenSunHaiFuWeiJin.params.money' && show
                  ? 'van-number-keyboard-input-select'
                  : ''
              "
            /> -->
            元
          </div>
          <div></div>
        </div>
      </div>

      <!-- 误工费 -->
      <div class="project" v-show="WuGongFei.checked">
        <div class="project-title">
          <div class="project-title-name">{{ WuGongFei.text }}</div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'WuGongFeiResult'
                setZero('WuGongFeiResult')
              "
              :class="keyboard === 'WuGongFeiResult' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="WuGongFeiResult !== ''">
                <span v-show="yuanToWan(WuGongFeiResult).wan > 0">
                  {{ yuanToWan(WuGongFeiResult).wan }}
                </span>
                <span v-show="yuanToWan(WuGongFeiResult).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(WuGongFeiResult).yuan }}</span>
              </span>
            </div>
            <!-- <input
              class="table-input-ji"
              v-model="WuGongFeiResult"
              clickable
              readonly
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'WuGongFeiResult'
                setZero('WuGongFeiResult')
              "
              :class="
                keyboard === 'WuGongFeiResult' && show
                  ? 'van-number-keyboard-input-select'
                  : ''
              "
            /> -->
            元
          </div>
          <div @click="WuGongFeiShow = !WuGongFeiShow">
            <van-icon v-show="WuGongFeiShow === false" name="arrow" />
            <van-icon v-show="WuGongFeiShow === true" name="arrow-down" />
          </div>
        </div>
        <div class="project-content" v-show="WuGongFeiShow === true">
          <div class="box" style="padding: 10px 0">
            <i class="iconfont icon-danxuan" v-show="WuGongFei.params.checked === 1" @click="WuGongFei.params.checked = 1"></i>
            <i class="iconfont icon-yuanxingweixuanzhong" v-show="WuGongFei.params.checked !== 1" @click="WuGongFei.params.checked = 1"></i>
            <span
              >有固定收入受害人工资<input
                class="table-input"
                v-model="WuGongFei.params.list[0].money"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'WuGongFei.params.list.0.money'
                "
                :class="keyboard === 'WuGongFei.params.list.0.money' && show ? 'van-number-keyboard-input-select' : ''"
              />元/天*误工天数<input
                class="table-input table-input-day"
                v-model="WuGongFei.params.list[0].day"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'WuGongFei.params.list.0.day'
                "
                :class="keyboard === 'WuGongFei.params.list.0.day' && show ? 'van-number-keyboard-input-select' : ''"
              />天</span
            >
          </div>
          <div class="box" style="padding: 10px 0">
            <i class="iconfont icon-danxuan" v-show="WuGongFei.params.checked === 2" @click="WuGongFei.params.checked = 2"></i>
            <i class="iconfont icon-yuanxingweixuanzhong" v-show="WuGongFei.params.checked !== 2" @click="WuGongFei.params.checked = 2"></i>
            <span>
              能证明 受害人最近三年平均收入<input
                class="table-input"
                v-model="WuGongFei.params.list[1].money"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'WuGongFei.params.list.1.money'
                "
                :class="keyboard === 'WuGongFei.params.list.1.money' && show ? 'van-number-keyboard-input-select' : ''"
              />元/天*误工天数<input
                class="table-input table-input-day"
                v-model="WuGongFei.params.list[1].day"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'WuGongFei.params.list.1.day'
                "
                :class="keyboard === 'WuGongFei.params.list.1.day' && show ? 'van-number-keyboard-input-select' : ''"
              />天
            </span>
          </div>
          <div class="box" style="padding: 10px 0">
            <i class="iconfont icon-danxuan" v-show="WuGongFei.params.checked === 3" @click="WuGongFei.params.checked = 3"></i>
            <i class="iconfont icon-yuanxingweixuanzhong" v-show="WuGongFei.params.checked !== 3" @click="WuGongFei.params.checked = 3"></i>
            <span
              >不能证明 相同或者相近行业上一年职工的平均工资<input
                class="table-input"
                v-model="WuGongFei.params.list[2].money"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'WuGongFei.params.list.2.money'
                "
                :class="keyboard === 'WuGongFei.params.list.2.money' && show ? 'van-number-keyboard-input-select' : ''"
              />元/天*误工天数<input
                class="table-input table-input-day"
                v-model="WuGongFei.params.list[2].day"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'WuGongFei.params.list.2.day'
                "
                :class="keyboard === 'WuGongFei.params.list.2.day' && show ? 'van-number-keyboard-input-select' : ''"
              />天
            </span>
          </div>
        </div>
      </div>

      <!-- 住院护理费 -->
      <div class="project" v-show="ZhuYuanHuLiFei.checked">
        <div class="project-title">
          <div class="project-title-name">{{ ZhuYuanHuLiFei.text }}</div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'ZhuYuanHuLiFeiResult'
                setZero('ZhuYuanHuLiFeiResult')
              "
              :class="keyboard === 'ZhuYuanHuLiFeiResult' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="ZhuYuanHuLiFeiResult !== ''">
                <span v-show="yuanToWan(ZhuYuanHuLiFeiResult).wan > 0">
                  {{ yuanToWan(ZhuYuanHuLiFeiResult).wan }}
                </span>
                <span v-show="yuanToWan(ZhuYuanHuLiFeiResult).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(ZhuYuanHuLiFeiResult).yuan }}</span>
              </span>
            </div>
            <!-- <input
              class="table-input-ji"
              v-model="ZhuYuanHuLiFeiResult"
              clickable
              readonly
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'ZhuYuanHuLiFeiResult'
                setZero('ZhuYuanHuLiFeiResult')
              "
              :class="
                keyboard === 'ZhuYuanHuLiFeiResult' && show
                  ? 'van-number-keyboard-input-select'
                  : ''
              "
            /> -->
            元
          </div>
          <div @click="ZhuYuanHuLiFeiShow = !ZhuYuanHuLiFeiShow">
            <van-icon v-show="ZhuYuanHuLiFeiShow === false" name="arrow" />
            <van-icon v-show="ZhuYuanHuLiFeiShow === true" name="arrow-down" />
          </div>
        </div>
        <div class="project-content" v-show="ZhuYuanHuLiFeiShow === true">
          <div class="box" style="padding: 10px 0">
            <i class="iconfont icon-danxuan" v-show="ZhuYuanHuLiFei.params.checked === 1" @click="ZhuYuanHuLiFei.params.checked = 1"></i>
            <i class="iconfont icon-yuanxingweixuanzhong" v-show="ZhuYuanHuLiFei.params.checked !== 1" @click="ZhuYuanHuLiFei.params.checked = 1"></i>
            <span
              >护理人员有收入 护理人工资<input
                class="table-input"
                v-model="ZhuYuanHuLiFei.params.list[0].money"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'ZhuYuanHuLiFei.params.list.0.money'
                "
                :class="keyboard === 'ZhuYuanHuLiFei.params.list.0.money' && show ? 'van-number-keyboard-input-select' : ''"
              />元/天*护理期限<input
                class="table-input table-input-day"
                v-model="ZhuYuanHuLiFei.params.list[0].day"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'ZhuYuanHuLiFei.params.list.0.day'
                "
                :class="keyboard === 'ZhuYuanHuLiFei.params.list.0.day' && show ? 'van-number-keyboard-input-select' : ''"
              />天</span
            >
          </div>
          <div class="box" style="padding: 10px 0">
            <i class="iconfont icon-danxuan" v-show="ZhuYuanHuLiFei.params.checked === 2" @click="ZhuYuanHuLiFei.params.checked = 2"></i>
            <i class="iconfont icon-yuanxingweixuanzhong" v-show="ZhuYuanHuLiFei.params.checked !== 2" @click="ZhuYuanHuLiFei.params.checked = 2"></i>
            <span
              >雇佣护工 护理标准<input
                class="table-input"
                v-model="ZhuYuanHuLiFei.params.list[1].money"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'ZhuYuanHuLiFei.params.list.1.money'
                "
                :class="keyboard === 'ZhuYuanHuLiFei.params.list.1.money' && show ? 'van-number-keyboard-input-select' : ''"
              />元/天*护理期限<input
                class="table-input table-input-day"
                v-model="ZhuYuanHuLiFei.params.list[1].day"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'ZhuYuanHuLiFei.params.list.1.day'
                "
                :class="keyboard === 'ZhuYuanHuLiFei.params.list.1.day' && show ? 'van-number-keyboard-input-select' : ''"
              />天
            </span>
          </div>
        </div>
      </div>

      <!-- 出院护理费 -->
      <div class="project" v-show="ChuYuanHuLiFei.checked">
        <div class="project-title">
          <div class="project-title-name">{{ ChuYuanHuLiFei.text }}</div>
          <div class="project-title-input-box">
            计
            <div
              class="table-input-ji"
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'ChuYuanHuLiFeiResult'
                setZero('ChuYuanHuLiFeiResult')
              "
              :class="keyboard === 'ChuYuanHuLiFeiResult' && show ? 'van-number-keyboard-input-select' : ''"
            >
              <span v-show="ChuYuanHuLiFeiResult !== ''">
                <span v-show="yuanToWan(ChuYuanHuLiFeiResult).wan > 0">
                  {{ yuanToWan(ChuYuanHuLiFeiResult).wan }}
                </span>
                <span v-show="yuanToWan(ChuYuanHuLiFeiResult).wan > 0" style="font-size: 10px !important" class="table-input-ji-wan">万</span>
                <span>{{ yuanToWan(ChuYuanHuLiFeiResult).yuan }}</span>
              </span>
            </div>
            <!-- <input
              class="table-input-ji"
              v-model="ChuYuanHuLiFeiResult"
              clickable
              readonly
              @touchstart.stop="
                resultInputFlag = true
                show = true
                extraKey = '.'
                keyboard = 'ChuYuanHuLiFeiResult'
                setZero('ChuYuanHuLiFeiResult')
              "
              :class="
                keyboard === 'ChuYuanHuLiFeiResult' && show
                  ? 'van-number-keyboard-input-select'
                  : ''
              "
            /> -->
            元
          </div>
          <div @click="ChuYuanHuLiFeiShow = !ChuYuanHuLiFeiShow">
            <van-icon v-show="ChuYuanHuLiFeiShow === false" name="arrow" />
            <van-icon v-show="ChuYuanHuLiFeiShow === true" name="arrow-down" />
          </div>
        </div>
        <div class="project-content" v-show="ChuYuanHuLiFeiShow === true">
          <div class="box" style="padding: 10px 0">
            <i class="iconfont icon-danxuan" v-show="ChuYuanHuLiFei.params.checked === 1" @click="ChuYuanHuLiFei.params.checked = 1"></i>
            <i class="iconfont icon-yuanxingweixuanzhong" v-show="ChuYuanHuLiFei.params.checked !== 1" @click="ChuYuanHuLiFei.params.checked = 1"></i>
            <span
              >护理人员有收入 护理人工资<input
                class="table-input"
                v-model="ChuYuanHuLiFei.params.list[0].money"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'ChuYuanHuLiFei.params.list.0.money'
                "
                :class="keyboard === 'ChuYuanHuLiFei.params.list.0.money' && show ? 'van-number-keyboard-input-select' : ''"
              />元/天*护理期限<input
                class="table-input table-input-day"
                v-model="ChuYuanHuLiFei.params.list[0].day"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'ChuYuanHuLiFei.params.list.0.day'
                "
                :class="keyboard === 'ChuYuanHuLiFei.params.list.0.day' && show ? 'van-number-keyboard-input-select' : ''"
              />天</span
            >
          </div>
          <div class="box" style="padding: 10px 0">
            <i class="iconfont icon-danxuan" v-show="ChuYuanHuLiFei.params.checked === 2" @click="ChuYuanHuLiFei.params.checked = 2"></i>
            <i class="iconfont icon-yuanxingweixuanzhong" v-show="ChuYuanHuLiFei.params.checked !== 2" @click="ChuYuanHuLiFei.params.checked = 2"></i>
            <span
              >雇佣护工 护理标准<input
                class="table-input"
                v-model="ChuYuanHuLiFei.params.list[1].money"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'ChuYuanHuLiFei.params.list.1.money'
                "
                :class="keyboard === 'ChuYuanHuLiFei.params.list.1.money' && show ? 'van-number-keyboard-input-select' : ''"
              />元/天*护理期限<input
                class="table-input table-input-day"
                v-model="ChuYuanHuLiFei.params.list[1].day"
                readonly
                clickable
                @touchstart.stop="
                  resultInputFlag = false
                  show = true
                  extraKey = '.'
                  keyboard = 'ChuYuanHuLiFei.params.list.1.day'
                "
                :class="keyboard === 'ChuYuanHuLiFei.params.list.1.day' && show ? 'van-number-keyboard-input-select' : ''"
              />天
            </span>
          </div>
        </div>
      </div>

      <!-- 伤残赔偿 -->
      <div class="project" v-show="ShangCanPeiChang.checked">
        <div class="project-title" @click="ShangCanPeiChang.show = !ShangCanPeiChang.show">
          <div>{{ ShangCanPeiChang.text }}</div>
          <div v-show="ShangCanPeiChang.result">
            计<span style="color: red">{{ ShangCanPeiChang.result }}</span
            >元
          </div>
          <div>
            <van-icon v-show="ShangCanPeiChang.show === false" name="arrow" />
            <van-icon v-show="ShangCanPeiChang.show === true" name="arrow-down" />
          </div>
        </div>
        <div class="project-content" v-show="ShangCanPeiChang.show === true">
          <div class="box" style="padding: 10px 0; justify-content: space-between">
            <div style="display: flex; align-items: center">
              <div style="color: #999999; font-size: 12px !important">最高伤残等级</div>
              <div style="color: #0099ff; padding: 0 10px; display: flex; align-items: center; width: 80px !important">
                <van-field style="flex: 1; padding: 0; text-align: center; color: #0099ff" v-model="ShangCanPeiChang.params.firstDisabled" readonly clickable @click="firstDisabledShow = true" />
                <van-icon name="arrow-down" />
                <van-popup v-model="firstDisabledShow" position="top">
                  <van-radio-group v-model="ShangCanPeiChang.params.firstDisabled" @change="firstDisabledChange">
                    <van-cell-group>
                      <van-cell v-for="(item, i) in firstDisabled" clickable :key="i" :title="item.label" @click="onClickFirstDisabled(item, i)">
                        <van-radio :name="item.label" ref="checkboxes" slot="right-icon" shape="square" />
                      </van-cell>
                    </van-cell-group>
                  </van-radio-group>
                </van-popup>
              </div>
              <div style="color: #999999; font-size: 12px !important">
                {{ getDisabledPercentage('first', ShangCanPeiChang.params.firstDisabled) }}
              </div>
            </div>
            <div style="color: #999999" @click="addSecondDisabled(-1)">增加次级伤残<van-icon name="arrow-down" /></div>
            <van-popup v-model="secondDisabledShow" position="top">
              <van-radio-group v-model="secondDisabledValue" @change="secondDisabledChange">
                <van-cell-group>
                  <van-cell v-for="(item, i) in secondDisabledCopy" clickable :key="i" :title="item.label" @click="onClickSecondDisabled(item, i)">
                    <van-radio :name="item.label" slot="right-icon" shape="square" ref="checkboxesSecond" />
                  </van-cell>
                </van-cell-group>
              </van-radio-group>
            </van-popup>
          </div>
          <div class="box" style="padding: 10px 0; justify-content: space-between">
            <div>
              <div style="display: flex; align-items: center" v-for="(disabled, i) in ShangCanPeiChang.params.secondDisabled" :key="i">
                <div style="color: #999999">次级伤残</div>
                <div style="color: #0099ff; padding: 0 10px; display: flex; align-items: center">
                  <van-field style="width: 50px; flex: 1; padding: 0; text-align: center; color: #0099ff" v-model="disabled.label" readonly clickable @click="addSecondDisabled(i, disabled.label)" />
                  <van-icon name="arrow-down" />
                </div>
                <div style="color: #999999">
                  {{ getDisabledPercentage('second', disabled.label) }}
                </div>
              </div>
            </div>
          </div>
          <div class="box" style="padding: 10px 0; justify-content: space-between" v-show="time !== '' && address !== ''">
            <div>
              <span>
                <span>{{ parseInt(time) - 1 }}</span
                >年
              </span>
              <span>
                <span>{{ address }}</span
                >人均可支配收入
              </span>
              <span v-show="!ShangCanPeiChangIncomeInputShow" style="color: #0099ff; cursor: pointer; padding: 0 5px" @click="ShangCanPeiChangIncomeInputShow = true">
                {{ ShangCanPeiChang.params.income }}
              </span>
              <div v-show="ShangCanPeiChangIncomeInputShow" style="display: inline-block">
                <input
                  class="table-input"
                  v-model="ShangCanPeiChang.params.income"
                  @change="addIncome('ShangCanPeiChang')"
                  readonly
                  clickable
                  @touchstart.stop="
                    resultInputFlag = false
                    show = true
                    extraKey = '.'
                    keyboard = 'ShangCanPeiChang.params.income'
                  "
                  :class="keyboard === 'ShangCanPeiChang.params.income' && show ? 'van-number-keyboard-input-select' : ''"
                />
              </div>
              <span>
                元*({{ this.getShangCanPercentage().first * 100 }}%+{{ this.getShangCanPercentage().second * 100 }}%)*<span>{{ ShangCanPeiChang.params.year }}年</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- 交强险责任分摊 -->
      <div class="project" v-show="insurance && (SiWangPeiChangJin.checked || YiLiaoFei.checked)">
        <div class="project-title">
          <div>交强险责任分摊</div>
        </div>
        <div class="project-content">
          <div class="box" style="padding: 10px 0">
            <i class="iconfont icon-danxuan" v-show="isResponsibility === 1" @click="isResponsibility = 1"></i>
            <i class="iconfont icon-yuanxingweixuanzhong" v-show="isResponsibility !== 1" @click="isResponsibility = 1"></i>
            <span>有责任</span>
            <span style="flex: 1; margin-left: 20px">
              <span v-show="SiWangPeiChangJin.checked">死亡伤残赔偿限额：-180000元；</span>
              <span v-show="YiLiaoFei.checked">医疗费用赔偿限额：-18000元；</span>
            </span>
          </div>
          <div class="box" style="padding: 10px 0">
            <i class="iconfont icon-danxuan" v-show="isResponsibility === 2" @click="isResponsibility = 2"></i>
            <i class="iconfont icon-yuanxingweixuanzhong" v-show="isResponsibility !== 2" @click="isResponsibility = 2"></i>
            <span>无责任</span>
            <span style="flex: 1; margin-left: 20px">
              <span v-show="SiWangPeiChangJin.checked">死亡伤残赔偿限额：-18000元；</span>
              <span v-show="YiLiaoFei.checked">医疗费用赔偿限额：-1800元；</span>
            </span>
          </div>
        </div>
      </div>

      <div class="deathCompensation-result" v-show="this.result !== ''">
        <div></div>
        <div>
          合计<span style="color: #ed3f3e">{{ this.result }}</span
          >元
        </div>
        <div v-show="this.result !== ''">
          <!-- <i class="iconfont icon-fuzhi" @click="copyAll"></i> -->
        </div>
        <div v-show="this.result === ''"></div>
      </div>

      <div class="interest-result">
        <span @click="isShowInfo = !isShowInfo" v-show="law">法条</span>
        <span v-show="!law"></span>
        <span>
          <span v-show="this.result !== ''" style="margin-right: 10px" @click="copyAll">复制</span>
          <span v-show="this.result !== '' && !iphone" style="margin-right: 10px" @click="downLoad">下载</span>
          <span @click="share" style="margin-right: 10px">分享</span>
          <span @click="feedback">反馈</span>
        </span>
      </div>
      <div class="interest-result" v-show="isShowInfo">
        <div v-html="law"></div>
      </div>
    </div>
    <van-popup v-model="startTimeShow" position="top">
      <van-picker title="选择死亡时间" show-toolbar :columns="columns" @confirm="startTimeConfirm" @cancel="startTimeShow = false" :default-index="50" />
    </van-popup>
    <error-correction
      v-if="errorCorrectionDialog"
      :time="errorCorrection.time"
      :unit="errorCorrection.unit"
      :address="errorCorrection.address"
      :name="errorCorrection.name"
      :value="errorCorrection.value"
      @close="closeErrorCorrectionDialog"
    />
    <van-number-keyboard
      :show="show"
      theme="custom"
      :extra-key="extraKey"
      close-button-text="完成"
      :title="keyboardTitle"
      @blur="
        show = false
        resultInputFlag = false
        keyboardTitle = ''
        keyboard = ''
        ShangCanPeiChangIncomeBlur()
      "
      @input="onInput"
      @delete="onDelete"
    />
  </fb-page>
</template>

<script>
import { mapState } from 'vuex'
import fontSizeComp from '../../components/font-size-comp.vue'
import goBackMixin from '@mixins/goBack'
import addressSelector from '../../components/addressSelector.vue'
import industrySelector from '../../components/industry-selector.vue'
import index from './index.js'
import ErrorCorrection from '../../components/error-correction.vue'

import toolHead from '../../components/toolHead.vue'
export default {
  name: 'deathCompensation',
  mixins: [goBackMixin, index],
  components: {
    fontSizeComp,
    toolHead,
    addressSelector,
    industrySelector,
    ErrorCorrection,
  },
  data() {
    return {
      keyboardTitle: '', // 键盘标题
      show: false,
      keyboard: '',
      extraKey: '',
      startTimeShow: false,
      startTimeDate: new Date(),
      maxDate: new Date(2100, 12, 31),
      minDate: new Date(1900, 1, 1),
      isShare: false, // 是否分享的
      result: '', // 合计费用
      isShowInfo: false, // 是否显示详情
      law: '', // 法条
      age: '', // 死亡时年龄
      time: '', // 死亡年份
      options: [], // 地址数组
      address: '', // 地址
      income: '', // 人均可支配收入
      expenditure: '', // 人均年消费支出
      allChecked: false, // 全选
      insurance: false, // 交强险责任分摊
      text: '', // 文字内容
      resultObj: {}, // 导出数据的存放
      SiWangPeiChangJinShow: true,
      SangZangFeiShow: true,
      WuGongFeiShow: true,
      ZhuYuanHuLiFeiShow: true,
      ChuYuanHuLiFeiShow: true,
      BeiFuYangRenShengHuoFeiShow: true,
      ShangCanPeiChangShow: true,
      // 死亡赔偿金
      SiWangPeiChangJin: {
        show: true,
        checked: true,
        text: '死亡赔偿金',
        params: {
          address: '',
          text: '',
          income: '',
          year: '',
          income2: 0, // 人均经营净收入，江苏版本有效
          coefficient: 1, // 系数，江苏版本有效
        },
        result: '',
        law: '法律依据最高人民法院《关于审理人身损害赔偿案件适用法律若干问题的解释》第29条 死亡赔偿金按照受诉法院所在地上一年度城镇居民人均可支配收入或者农村居民人均纯收入标准，按二十年计算。但六十周岁以上的，年龄每增加一岁减少一年;七十五周岁以上的，按五年计算。第30条 赔偿权利人举证证明其住所地或者经常居住地城镇居民人均可支配收入或者农村居民人均纯收入高于受诉法院所在地标准的，残疾赔偿金或者死亡赔偿金可以按照其住所地或者经常居住地的相关标准计算。最高人民法院民一庭《经常居住在城镇的农村居民因交通事故死亡如何计算赔偿费用的复函》人身损害赔偿案件中，残疾赔偿金、死亡赔偿金和被扶养人生活费的计算，应当根据案件的实际情况，结合受害人住所地、经常居住地等因素，确定适用城镇居民人均可支配收入(人均消费性支出)或者农村居民人均纯收入(人均年生活消费支出)的标准。',
      },
      SiWangPeiChangJinResult: 0,
      // 丧葬费
      SangZangFei: {
        checked: true,
        show: true,
        text: '丧葬费',
        params: {
          money: '',
          address: '',
          industry: '',
          time: '',
        },
        result: '',
        law: '《最高人民法院关于审理人身损害赔偿案件适用法律若干问题的解释》第27条规定，“丧葬费按照受诉法院所在地上一年度职工月平均工资标准，以六个月总额计算。”',
      },
      SangZangFeiResult: '',
      SangZangFeiResultInput: false,
      // 医疗费
      YiLiaoFei: {
        show: true,
        checked: false,
        text: '医疗费',
        params: {
          money: '',
        },
        law: '《最高人民法院关于审理人身损害赔偿案件适用法律若干问题的解释》第19条规定，“医疗费根据医疗机构出具的医药费、住院费等收款凭证，结合病历和诊断证明等相关证据确定。赔偿义务人对治疗的必要性和合理性有异议的，应当承担相应的举证责任。”“医疗费支出的凭据，应为县级以上直属医院的医疗收费单据。',
      },
      // 交通费
      JiaoTongFei: {
        show: true,
        checked: false,
        text: '交通费',
        params: {
          money: '',
        },
        law: '《最高人民法院关于审理人身损害赔偿案件适用法律若干问题的解释》第22条规定，“交通费根据受害人及其必要的陪护人员因就医或者转院治疗实际发生的费用计算。交通费应当以正式票据为凭;有关凭据应当与就医地点、时间、人数、次数相符合。”',
      },
      // 住宿费
      ZhuSuFei: {
        show: true,
        checked: false,
        text: '住宿费',
        params: {
          money: '',
        },
        law: '',
      },
      // 精神损害抚慰金
      JingShenSunHaiFuWeiJin: {
        show: true,
        checked: false,
        text: '精神损害抚慰金',
        params: {
          money: '',
        },
        law: '',
      },
      // 误工费
      WuGongFei: {
        show: true,
        checked: false,
        text: '误工费',
        params: {
          checked: 1,
          list: [
            {
              day: '',
              money: '',
            },
            {
              day: '',
              money: '',
            },
            {
              day: '',
              money: '',
            },
          ],
        },
        result: '',
        law: '误工费是指受害人因遭受人身伤害，致使无法进行正常工作或进行正常经营活动而丧失的工资收入或者经营收入。是一种积极的财产减损，表现为财产的应增加而未增加。',
      },
      WuGongFeiResult: '',
      WuGongFeiResultInput: false,
      // 住院护理费
      ZhuYuanHuLiFei: {
        show: true,
        checked: false,
        text: '住院护理费',
        params: {
          checked: 1,
          list: [
            {
              day: '',
              money: '',
            },
            {
              day: '',
              money: '',
            },
          ],
        },
        result: '',
        law: '',
      },
      ZhuYuanHuLiFeiResult: '',
      ZhuYuanHuLiFeiResultInput: false,
      // 出院护理费
      ChuYuanHuLiFei: {
        show: true,
        checked: false,
        text: '出院护理费',
        params: {
          checked: 1,
          list: [
            {
              day: '',
              money: '',
            },
            {
              day: '',
              money: '',
            },
          ],
        },
        result: '',
        law: '',
      },
      ChuYuanHuLiFeiResult: '',
      ChuYuanHuLiFeiResultInput: false,
      // 被抚养人生活费
      BeiFuYangRenShengHuoFei: {
        show: true,
        checked: false,
        text: '被抚养人生活费',
        params: {
          address: '',
          income: '',
          checked1: false,
          checked2: false,
          checked3: false,
          checked4: false,
          list1: [
            {
              year: '',
              month: '',
              num: '',
            },
          ],
          list2: [
            {
              num: '',
            },
          ],
          list3: [
            {
              year: '',
              month: '',
              num: '',
            },
          ],
          list4: [
            {
              num: '',
            },
          ],
        },
        result: '',
        expenditure: '',
        law: '最高人民法院关于审理人身损害赔偿案件适用法律若干问题的解释》第28条规定：“被扶养人生活费根据扶养人丧失劳动能力程度，按照受诉法院所在地上一年度城镇居民人均消费性支出和农村居民人均年生活消费支出标准计算。被扶养人为未成年人的，计算至十八周岁;被扶养人无劳动能力又无其他生活来源的，计算二十年。但六十周岁以上的，年龄每增加一岁减少一年;七十五周岁以上的，按五年计算。被扶养人是指受害人依法应当承担扶养义务的未成年人或者丧失劳动能力又无其他生活来源的成年近亲属。被扶养人还有其他扶养人的，赔偿义务人只赔偿受害人依法应当负担的部分。被扶养人有数人的，年赔偿总额累计不超过上一年度城镇居民人均消费性支出额或者农村居民人均年生活消费支出额。”',
      },
      BeiFuYangRenShengHuoFeiResult: '',
      BeiFuYangRenShengHuoFeiResultInput: false,
      // 最高伤残等级
      firstDisabled: [
        {
          label: '一级',
          value: 1,
          percentage: '100%',
          size: 10,
        },
        {
          label: '二级',
          value: 0.9,
          percentage: '90%',
          size: 9,
        },
        {
          label: '三级',
          value: 0.8,
          percentage: '80%',
          size: 8,
        },
        {
          label: '四级',
          value: 0.7,
          percentage: '70%',
          size: 7,
        },
        {
          label: '五级',
          value: 0.6,
          percentage: '60%',
          size: 6,
        },
        {
          label: '六级',
          value: 0.5,
          percentage: '50%',
          size: 5,
        },
        {
          label: '七级',
          value: 0.4,
          percentage: '40%',
          size: 4,
        },
        {
          label: '八级',
          value: 0.3,
          percentage: '30%',
          size: 3,
        },
        {
          label: '九级',
          value: 0.2,
          percentage: '20%',
          size: 2,
        },
        {
          label: '十级',
          value: 0.1,
          percentage: '10%',
          size: 1,
        },
      ],
      // 次级伤残等级
      secondDisabled: [
        {
          label: '二级',
          value: 0.04,
          percentage: '4%',
          size: 9,
        },
        {
          label: '三级',
          value: 0.04,
          percentage: '4%',
          size: 8,
        },
        {
          label: '四级',
          value: 0.04,
          percentage: '4%',
          size: 7,
        },
        {
          label: '五级',
          value: 0.04,
          percentage: '4%',
          size: 6,
        },
        {
          label: '六级',
          value: 0.02,
          percentage: '2%',
          size: 5,
        },
        {
          label: '七级',
          value: 0.02,
          percentage: '2%',
          size: 4,
        },
        {
          label: '八级',
          value: 0.02,
          percentage: '2%',
          size: 3,
        },
        {
          label: '九级',
          value: 0.02,
          percentage: '2%',
          size: 2,
        },
        {
          label: '十级',
          value: 0.02,
          percentage: '2%',
          size: 1,
        },
      ],
      secondDisabledValue: '',
      secondDisabledCopy: [
        {
          label: '二级',
          value: 0.04,
          percentage: '4%',
          size: 9,
        },
        {
          label: '三级',
          value: 0.04,
          percentage: '4%',
          size: 8,
        },
        {
          label: '四级',
          value: 0.04,
          percentage: '4%',
          size: 7,
        },
        {
          label: '五级',
          value: 0.04,
          percentage: '4%',
          size: 6,
        },
        {
          label: '六级',
          value: 0.02,
          percentage: '2%',
          size: 5,
        },
        {
          label: '七级',
          value: 0.02,
          percentage: '2%',
          size: 4,
        },
        {
          label: '八级',
          value: 0.02,
          percentage: '2%',
          size: 3,
        },
        {
          label: '九级',
          value: 0.02,
          percentage: '2%',
          size: 2,
        },
        {
          label: '十级',
          value: 0.02,
          percentage: '2%',
          size: 1,
        },
      ],
      ShangCanPeiChangIncomeInputShow: false,
      firstDisabledShow: false,
      secondDisabledShow: false,
      // 伤残赔偿
      ShangCanPeiChang: {
        show: false,
        checked: false,
        text: '伤残赔偿',
        params: {
          income: 0,
          firstDisabled: '一级',
          secondDisabled: [],
          year: '',
        },
        result: '',
        law: '《最高人民法院关于审理人身损害赔偿案件适用法律若干问题的解释》第25条残疾赔偿金根据受丰人丧失劳动能力程度或者伤残等级，按照受诉法院所在地上一年度城镇居民人均可支配收入或者农村居民人均纯收入标准，自定残之日起按二十年计算。但六十周岁以上的，年龄每增加一岁减少一年;七十五周岁以上的，按五年计算。',
      },
      // 交强险责任分摊 是否有责任
      isResponsibility: 0,
      titleObj: {
        yes: '',
        no: '',
      },
      errorCorrectionDialog: false,
      errorCorrection: {
        time: 0,
        name: '',
        address: '',
        unit: '',
        value: 0,
      },
      iphone: false,
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    createCaseWrapperStyle() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      const h = document.documentElement.clientHeight
      const height = h - 44 * dpr + 30
      const style = {
        height: `${height}px`,
      }
      return style
    },
  },
  mthods: {},
  watch: {
    // allChecked(value) {
    //   this.selectAll(value)
    // },
    age(val) {
      val = val.substr(0, 3)
      this.age = val.replace(/[^\d]/g, '')
      this.getResult()
      this.setKeyboardTitle(this.age, 'age')
    },
    time(val) {
      this.time = val.replace(/[^\d]/g, '')
      this.SangZangFei.params.time = parseInt(this.time) - 1
      if (this.time) {
        this.getResult()
      }
    },
    address(val) {
      if (val) {
        this.getResult()
      }
    },
    income(val) {
      this.income = val.replace(/[^\d.]/g, '')
      this.getResult()
    },
    expenditure(val) {
      this.expenditure = val.replace(/[^\d.]/g, '')
      if (this.expenditure) {
        this.getResult()
      }
    },
    insurance(val) {
      this.getResult()
    },
    isResponsibility(val) {
      this.getResult()
    },
    YiLiaoFei: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        this.YiLiaoFei.params.money = val.params.money.replace(/[^\d.]/g, '')
        this.YiLiaoFei.params.money = val.params.money.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        setTimeout(() => {
          this.getResult()
        })
      },
    },
    JiaoTongFei: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        this.JiaoTongFei.params.money = val.params.money.replace(/[^\d.]/g, '')
        this.JiaoTongFei.params.money = val.params.money.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        setTimeout(() => {
          this.getResult()
        })
      },
    },
    ZhuSuFei: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        this.ZhuSuFei.params.money = val.params.money.replace(/[^\d.]/g, '')
        this.ZhuSuFei.params.money = val.params.money.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        setTimeout(() => {
          this.getResult()
        })
      },
    },
    JingShenSunHaiFuWeiJin: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        this.JingShenSunHaiFuWeiJin.params.money = val.params.money.replace(/[^\d.]/g, '')
        this.JingShenSunHaiFuWeiJin.params.money = val.params.money.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        setTimeout(() => {
          this.getResult()
        })
      },
    },
    WuGongFei: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        this.WuGongFei.params.list.map((item, index) => {
          item.day = val.params.list[index].day.replace(/[^\d.]/g, '')
          item.money = val.params.list[index].money.replace(/[^\d.]/g, '')
          item.money = val.params.list[index].money.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        })
        setTimeout(() => {
          this.getResult('WuGongFei')
        })
      },
    },
    ZhuYuanHuLiFei: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        this.ZhuYuanHuLiFei.params.list.map((item, index) => {
          item.day = val.params.list[index].day.replace(/[^\d.]/g, '')
          item.money = val.params.list[index].money.replace(/[^\d.]/g, '')
          item.money = val.params.list[index].money.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        })
        setTimeout(() => {
          this.getResult('ZhuYuanHuLiFei')
        })
      },
    },
    ChuYuanHuLiFei: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        this.ChuYuanHuLiFei.params.list.map((item, index) => {
          item.day = val.params.list[index].day.replace(/[^\d.]/g, '')
          item.money = val.params.list[index].money.replace(/[^\d.]/g, '')
          item.money = val.params.list[index].money.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        })
        setTimeout(() => {
          this.getResult('ChuYuanHuLiFei')
        })
      },
    },
    BeiFuYangRenShengHuoFei: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        this.BeiFuYangRenShengHuoFei.params.income = val.params.income.replace(/[^\d.]/g, '')
        this.BeiFuYangRenShengHuoFei.params.income = val.params.income.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        this.BeiFuYangRenShengHuoFei.params.list1.map((item, index) => {
          val.params.list1[index].year = val.params.list1[index].year.substr(0, 3)
          item.year = val.params.list1[index].year.replace(/[^\d]/g, '')
          item.num = val.params.list1[index].num.replace(/[^\d]/g, '')
          item.month = val.params.list1[index].month.replace(/[^\d]/g, '')
        })
        this.BeiFuYangRenShengHuoFei.params.list2.map((item, index) => {
          item.num = val.params.list2[index].num.replace(/[^\d]/g, '')
        })
        this.BeiFuYangRenShengHuoFei.params.list3.map((item, index) => {
          val.params.list3[index].year = val.params.list3[index].year.substr(0, 3)
          item.year = val.params.list3[index].year.replace(/[^\d]/g, '')
          item.num = val.params.list3[index].num.replace(/[^\d]/g, '')
          item.month = val.params.list3[index].month.replace(/[^\d]/g, '')
        })
        this.BeiFuYangRenShengHuoFei.params.list4.map((item, index) => {
          item.num = val.params.list4[index].num.replace(/[^\d]/g, '')
        })
        setTimeout(() => {
          this.getResult('BeiFuYangRenShengHuoFei')
        })
        setTimeout(() => {
          const keys = this.keyboard.split('.')
          if (keys.length === 5) {
            console.log(this[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]])
            this.setKeyboardTitle(this[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]], this.keyboard)
            // 填写被抚养人的内容，就选中前面的框
            if (this[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]) {
              const i = keys[2].indexOf('list')
              const index = i === -1 ? '0' : keys[2].substr(4)
              const checked = 'checked' + index
              if (this.BeiFuYangRenShengHuoFei.params[checked] !== undefined) {
                this.BeiFuYangRenShengHuoFei.params[checked] = true
              }
            }
          }
        }, 100)
      },
    },
    SiWangPeiChangJin: {
      deep: true,
      handler(val) {
        this.SiWangPeiChangJin.params.income = val.params.income.replace(/[^\d.]/g, '')
        this.SiWangPeiChangJin.params.income = val.params.income.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        this.isSelectAll()
        setTimeout(() => {
          this.getResult('SiWangPeiChangJin')
        })
      },
    },
    SangZangFei: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        this.SangZangFei.params.money = val.params.money.replace(/[^\d.]/g, '')
        this.SangZangFei.params.money = val.params.money.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        setTimeout(() => {
          this.getResult('SangZangFei')
        })
      },
    },
    ShangCanPeiChang: {
      deep: true,
      handler(val) {
        this.isSelectAll()
        let size = 0
        for (var i = 0; i < this.firstDisabled.length; i++) {
          if (this.firstDisabled[i].label === val.params.firstDisabled) {
            size = this.firstDisabled[i].size
          }
        }
        val.params.secondDisabled.map((item) => {
          let secondSize = 0
          this.secondDisabled.map((itema) => {
            if (item.label === itema.label) {
              secondSize = itema.size
            }
          })
          if (secondSize > size) {
            item.label = ''
          }
        })
        this.ShangCanPeiChang.params.income = (val.params.income + '').replace(/[^\d.]/g, '')
        this.ShangCanPeiChang.params.income = (val.params.income + '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        setTimeout(() => {
          this.getResult()
        })
      },
    },
    keyboard(val) {
      let titleObj = {}
      switch (val) {
        case 'age':
          titleObj = {
            key: 'age',
            no: '请输入年龄',
            yes: '伤残年龄###岁',
          }
          break
        case 'YiLiaoFei.params.money':
          titleObj = {
            key: 'YiLiaoFei.params.money',
            no: '请输入医疗费',
            yes: '医疗费计###元',
          }
          break
        case 'JiaoTongFei.params.money':
          titleObj = {
            key: 'JiaoTongFei.params.money',
            no: '请输入交通费',
            yes: '交通费计###元',
          }
          break
        case 'ZhuSuFei.params.money':
          titleObj = {
            key: 'ZhuSuFei.params.money',
            no: '请输入住宿费费',
            yes: '住宿费计###元',
          }
          break
        case 'JingShenSunHaiFuWeiJin.params.money':
          titleObj = {
            key: 'JingShenSunHaiFuWeiJin.params.money',
            no: '请输入精神损害抚慰金',
            yes: '精神损害抚慰金计###元',
          }
          break
        case 'WuGongFeiResult':
          titleObj = {
            key: 'WuGongFeiResult',
            no: '请输入误工费',
            yes: '误工费计###元',
          }
          break
        case 'WuGongFei.params.list.0.money':
          titleObj = {
            key: 'WuGongFei.params.list.0.money',
            no: '请输入误工工资',
            yes: '工资###元/天',
          }
          break
        case 'WuGongFei.params.list.0.day':
          titleObj = {
            key: 'WuGongFei.params.list.0.day',
            no: '请输入误工天数',
            yes: '误工天数###天',
          }
          break
        case 'WuGongFei.params.list.1.money':
          titleObj = {
            key: 'WuGongFei.params.list.1.money',
            no: '请输入平均收入',
            yes: '平均收入###元/天',
          }
          break
        case 'WuGongFei.params.list.1.day':
          titleObj = {
            key: 'WuGongFei.params.list.1.day',
            no: '请输入误工天数',
            yes: '误工天数###天',
          }
          break
        case 'WuGongFei.params.list.2.money':
          titleObj = {
            key: 'WuGongFei.params.list.2.money',
            no: '请输入平均工资',
            yes: '平均工资###天/天',
          }
          break
        case 'WuGongFei.params.list.2.day':
          titleObj = {
            key: 'WuGongFei.params.list.2.day',
            no: '请输入误工天数',
            yes: '误工天数###天',
          }
          break
        case 'ZhuYuanHuLiFeiResult':
          titleObj = {
            key: 'ZhuYuanHuLiFeiResult',
            no: '请输入住院护理费',
            yes: '住院护理费计###元',
          }
          break
        case 'ZhuYuanHuLiFei.params.list.0.money':
          titleObj = {
            key: 'ZhuYuanHuLiFei.params.list.0.money',
            no: '请输入护理人工资',
            yes: '护理人工资###元/天',
          }
          break
        case 'ZhuYuanHuLiFei.params.list.0.day':
          titleObj = {
            key: 'ZhuYuanHuLiFei.params.list.0.day',
            no: '请输入护理期限',
            yes: '护理期限###天',
          }
          break
        case 'ZhuYuanHuLiFei.params.list.1.money':
          titleObj = {
            key: 'ZhuYuanHuLiFei.params.list.1.money',
            no: '请输入护理标准',
            yes: '护理标准###元/天',
          }
          break
        case 'ZhuYuanHuLiFei.params.list.1.day':
          titleObj = {
            key: 'ZhuYuanHuLiFei.params.list.1.day',
            no: '请输入护理期限',
            yes: '护理期限###天',
          }
          break
        case 'ChuYuanHuLiFeiResult':
          titleObj = {
            key: 'ChuYuanHuLiFeiResult',
            no: '请输入住院护理费',
            yes: '住院护理费计###元',
          }
          break
        case 'ChuYuanHuLiFei.params.list.0.money':
          titleObj = {
            key: 'ChuYuanHuLiFei.params.list.0.money',
            no: '请输入护理人工资',
            yes: '护理人工资###元/天',
          }
          break
        case 'ChuYuanHuLiFei.params.list.0.day':
          titleObj = {
            key: 'ChuYuanHuLiFei.params.list.0.day',
            no: '请输入护理期限',
            yes: '护理期限###天',
          }
          break
        case 'ChuYuanHuLiFei.params.list.1.money':
          titleObj = {
            key: 'ChuYuanHuLiFei.params.list.1.money',
            no: '请输入护理标准',
            yes: '护理标准###元/天',
          }
          break
        case 'ChuYuanHuLiFei.params.list.1.day':
          titleObj = {
            key: 'ChuYuanHuLiFei.params.list.1.day',
            no: '请输入护理期限',
            yes: '护理期限###天',
          }
          break
        case 'BeiFuYangRenShengHuoFeiResult':
          titleObj = {
            key: 'BeiFuYangRenShengHuoFeiResult',
            no: '请输入被抚养人生活费',
            yes: '被抚养人生活费计###元',
          }
          break
        case 'BeiFuYangRenShengHuoFei.params.income':
          titleObj = {
            key: 'BeiFuYangRenShengHuoFei.params.income',
            no: '请输入人均年消费性支出',
            yes: '人均年消费性支出###元',
          }
          break
        case 'SiWangPeiChangJinResult':
          titleObj = {
            key: 'SiWangPeiChangJinResult',
            no: '请输入死亡赔偿金',
            yes: '死亡赔偿金计###元',
          }
          break
        case 'SiWangPeiChangJin.params.income':
          titleObj = {
            key: 'SiWangPeiChangJin.params.income',
            no: '请输入人均可支配收入',
            yes: '人均可支配收入###元',
          }
          break
        case 'SangZangFeiResult':
          titleObj = {
            key: 'SangZangFeiResult',
            no: '请输入丧葬费',
            yes: '丧葬费计###元',
          }
          break
        case 'SangZangFei.params.money':
          titleObj = {
            key: 'SangZangFei.params.money',
            no: '请输入职工年平均工资',
            yes: '职工年平均工资###元',
          }
          break
      }
      if (titleObj.key) {
        this.titleObj = titleObj
      }
      const keys = val.split('.')
      if (keys.length === 1) {
        this.setKeyboardTitle(this[keys[0]], val)
      } else if (keys.length === 3) {
        this.setKeyboardTitle(this[keys[0]][keys[1]][keys[2]], val)
      } else if (keys.length === 4) {
        this.setKeyboardTitle(this[keys[0]][keys[1]][keys[2]][keys[3]], val)
      } else if (keys.length === 5) {
        this.setKeyboardTitle(this[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]], val)
      }
    },
    'YiLiaoFei.params.money': {
      handler(val) {
        this.setKeyboardTitle(this.YiLiaoFei.params.money, 'YiLiaoFei.params.money')
      },
    },
    'JiaoTongFei.params.money': {
      handler(val) {
        this.setKeyboardTitle(this.JiaoTongFei.params.money, 'JiaoTongFei.params.money')
      },
    },
    'ZhuSuFei.params.money': {
      handler(val) {
        this.setKeyboardTitle(this.ZhuSuFei.params.money, 'ZhuSuFei.params.money')
      },
    },
    'JingShenSunHaiFuWeiJin.params.money': {
      handler(val) {
        this.setKeyboardTitle(this.JingShenSunHaiFuWeiJin.params.money, 'JingShenSunHaiFuWeiJin.params.money')
      },
    },
    WuGongFeiResult: {
      handler(val) {
        this.WuGongFeiResult = (val + '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        this.setKeyboardTitle(this.WuGongFeiResult, 'WuGongFeiResult')
      },
    },
    'WuGongFei.params.list.0.money': {
      handler(val) {
        this.setKeyboardTitle(this.WuGongFei.params.list[0].money, 'WuGongFei.params.list.0.money')
      },
    },
    'WuGongFei.params.list.0.day': {
      handler(val) {
        this.setKeyboardTitle(this.WuGongFei.params.list[0].day, 'WuGongFei.params.list.0.day')
      },
    },
    'WuGongFei.params.list.1.money': {
      handler(val) {
        this.setKeyboardTitle(this.WuGongFei.params.list[1].money, 'WuGongFei.params.list.1.money')
      },
    },
    'WuGongFei.params.list.1.day': {
      handler(val) {
        this.setKeyboardTitle(this.WuGongFei.params.list[1].day, 'WuGongFei.params.list.1.day')
      },
    },
    'WuGongFei.params.list.2.money': {
      handler(val) {
        this.setKeyboardTitle(this.WuGongFei.params.list[2].money, 'WuGongFei.params.list.2.money')
      },
    },
    'WuGongFei.params.list.2.day': {
      handler(val) {
        this.setKeyboardTitle(this.WuGongFei.params.list[2].day, 'WuGongFei.params.list.2.day')
      },
    },
    ZhuYuanHuLiFeiResult: {
      handler(val) {
        this.ZhuYuanHuLiFeiResult = (val + '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        this.setKeyboardTitle(this.ZhuYuanHuLiFeiResult, 'ZhuYuanHuLiFeiResult')
      },
    },
    'ZhuYuanHuLiFei.params.list.0.money': {
      handler(val) {
        this.setKeyboardTitle(this.ZhuYuanHuLiFei.params.list[0].money, 'ZhuYuanHuLiFei.params.list.0.money')
      },
    },
    'ZhuYuanHuLiFei.params.list.0.day': {
      handler(val) {
        this.setKeyboardTitle(this.ZhuYuanHuLiFei.params.list[0].day, 'ZhuYuanHuLiFei.params.list.0.day')
      },
    },
    'ZhuYuanHuLiFei.params.list.1.money': {
      handler(val) {
        this.setKeyboardTitle(this.ZhuYuanHuLiFei.params.list[1].money, 'ZhuYuanHuLiFei.params.list.1.money')
      },
    },
    'ZhuYuanHuLiFei.params.list.1.day': {
      handler(val) {
        this.setKeyboardTitle(this.ZhuYuanHuLiFei.params.list[1].day, 'ZhuYuanHuLiFei.params.list.1.day')
      },
    },
    ChuYuanHuLiFeiResult: {
      handler(val) {
        this.ChuYuanHuLiFeiResult = (val + '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        this.setKeyboardTitle(this.ChuYuanHuLiFeiResult, 'ChuYuanHuLiFeiResult')
      },
    },
    'ChuYuanHuLiFei.params.list.0.money': {
      handler(val) {
        this.setKeyboardTitle(this.ChuYuanHuLiFei.params.list[0].money, 'ChuYuanHuLiFei.params.list.0.money')
      },
    },
    'ChuYuanHuLiFei.params.list.0.day': {
      handler(val) {
        this.setKeyboardTitle(this.ChuYuanHuLiFei.params.list[0].day, 'ChuYuanHuLiFei.params.list.0.day')
      },
    },
    'ChuYuanHuLiFei.params.list.1.money': {
      handler(val) {
        this.setKeyboardTitle(this.ChuYuanHuLiFei.params.list[1].money, 'ChuYuanHuLiFei.params.list.1.money')
      },
    },
    'ChuYuanHuLiFei.params.list.1.day': {
      handler(val) {
        this.setKeyboardTitle(this.ChuYuanHuLiFei.params.list[1].day, 'ChuYuanHuLiFei.params.list.1.day')
      },
    },
    BeiFuYangRenShengHuoFeiResult: {
      handler(val) {
        this.BeiFuYangRenShengHuoFeiResult = (val + '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        this.setKeyboardTitle(this.BeiFuYangRenShengHuoFeiResult, 'BeiFuYangRenShengHuoFeiResult')
      },
    },
    'BeiFuYangRenShengHuoFei.params.income': {
      handler(val) {
        this.setKeyboardTitle(this.BeiFuYangRenShengHuoFei.params.income, 'BeiFuYangRenShengHuoFei.params.income')
      },
    },
    SiWangPeiChangJinResult: {
      handler(val) {
        this.SiWangPeiChangJinResult = (val + '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        this.setKeyboardTitle(this.SiWangPeiChangJinResult, 'SiWangPeiChangJinResult')
      },
    },
    'SiWangPeiChangJin.params.income': {
      handler(val) {
        this.setKeyboardTitle(this.SiWangPeiChangJin.params.income, 'SiWangPeiChangJin.params.income')
      },
    },
    SangZangFeiResult: {
      handler(val) {
        this.SangZangFeiResult = (val + '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        this.setKeyboardTitle(this.SangZangFeiResult, 'SangZangFeiResult')
      },
    },
    'SangZangFei.params.money': {
      handler(val) {
        this.setKeyboardTitle(this.SangZangFei.params.income, 'SangZangFei.params.income')
      },
    },
  },
  created() {
    this.columns = []
    let newY = new Date().getFullYear()
    newY += 50
    this.columns.push(newY + '')
    for (var i = 0; i < 100; i++) {
      newY--
      this.columns.push(newY + '')
    }
    if (this.$route.query.data) {
      this.isShare = true
    } else {
      this.isShare = false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.fontSizeComp.fontSize = 20
      this.$refs.fontSizeComp.setFontSize()
    })
    this.time = new Date().getFullYear() + ''
    if (this.userInfo && this.userInfo.province) {
      this.address = this.userInfo.province
      this.handleSelect({ shortName: this.address })
    }
  },
}
</script>
<style lang="stylus">
@import './index.styl'
</style>
