var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "死亡损害赔偿金", fixed: "" },
          slot: "header",
        },
        [
          !_vm.isShare
            ? _c(
                "fb-button",
                {
                  attrs: {
                    slot: "left",
                    icon: "back",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.backToNative()
                    },
                  },
                  slot: "left",
                },
                [_vm._v(" 返回 ")]
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "tool-head",
                {
                  attrs: { type: "deathCompensation" },
                  on: { clickItem: _vm.clickItem },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { "margin-right": "10px" },
                          on: { click: _vm.reset },
                        },
                        [_vm._v("还原")]
                      ),
                      _c("font-size-comp", {
                        ref: "fontSizeComp",
                        on: { setFontSize: _vm.setFontSize },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tools-deathCompensation",
          staticStyle: { "font-size": "14px !important" },
          style: _vm.createCaseWrapperStyle,
          attrs: { id: "tools" },
        },
        [
          _c("div", { staticClass: "interest-content" }, [
            _c("div", { staticClass: "box" }, [
              _c(
                "div",
                { staticClass: "box-left" },
                [
                  _c("span", { staticClass: "box-title" }, [
                    _vm._v("死亡年龄"),
                  ]),
                  _c("van-field", {
                    class:
                      _vm.keyboard === "age" && _vm.show
                        ? "van-number-keyboard-input-select"
                        : "",
                    attrs: {
                      readonly: "",
                      clickable: "",
                      placeholder: "请输入",
                    },
                    on: {
                      touchstart: function ($event) {
                        $event.stopPropagation()
                        _vm.resultInputFlag = false
                        _vm.show = true
                        _vm.extraKey = ""
                        _vm.keyboard = "age"
                      },
                    },
                    model: {
                      value: _vm.age,
                      callback: function ($$v) {
                        _vm.age = $$v
                      },
                      expression: "age",
                    },
                  }),
                  _c("span", { staticClass: "box-title" }, [_vm._v("岁")]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box-right" },
                [
                  _c("span", { staticClass: "box-title" }),
                  _c("van-field", {
                    attrs: {
                      placeholder: "死亡时间",
                      readonly: "",
                      clickable: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.startTimeShow = true
                      },
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                  _c("span", { staticClass: "box-title" }, [_vm._v("年")]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "box",
                  staticStyle: { flex: "1", "margin-left": "20px" },
                },
                [
                  _c("address-selector", {
                    attrs: { address: this.address },
                    on: {
                      handleSelect: _vm.handleSelect,
                      upAddress: _vm.upAddress,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "box", staticStyle: { padding: "20px 0 11px 0" } },
              [
                _c(
                  "div",
                  { staticClass: "box-left" },
                  [
                    _c("span", { staticStyle: { color: "#cccccc" } }, [
                      _vm._v("项目"),
                    ]),
                    _c(
                      "van-checkbox",
                      {
                        attrs: { shape: "square" },
                        on: { click: _vm.allCheckedChange },
                        model: {
                          value: _vm.allChecked,
                          callback: function ($$v) {
                            _vm.allChecked = $$v
                          },
                          expression: "allChecked",
                        },
                      },
                      [_vm._v("全选")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "box-right" }, [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "van-checkbox",
                        {
                          attrs: { shape: "square" },
                          model: {
                            value: _vm.insurance,
                            callback: function ($$v) {
                              _vm.insurance = $$v
                            },
                            expression: "insurance",
                          },
                        },
                        [_vm._v("交强险责任分担")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "check-group",
                staticStyle: { "border-bottom": "1px solid #ccc" },
              },
              [
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.SiWangPeiChangJin.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.SiWangPeiChangJin, "checked", $$v)
                      },
                      expression: "SiWangPeiChangJin.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.SiWangPeiChangJin.text))]
                ),
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.SangZangFei.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.SangZangFei, "checked", $$v)
                      },
                      expression: "SangZangFei.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.SangZangFei.text))]
                ),
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.BeiFuYangRenShengHuoFei.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.BeiFuYangRenShengHuoFei, "checked", $$v)
                      },
                      expression: "BeiFuYangRenShengHuoFei.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.BeiFuYangRenShengHuoFei.text))]
                ),
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.YiLiaoFei.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.YiLiaoFei, "checked", $$v)
                      },
                      expression: "YiLiaoFei.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.YiLiaoFei.text))]
                ),
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.JiaoTongFei.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.JiaoTongFei, "checked", $$v)
                      },
                      expression: "JiaoTongFei.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.JiaoTongFei.text))]
                ),
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.ZhuSuFei.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.ZhuSuFei, "checked", $$v)
                      },
                      expression: "ZhuSuFei.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.ZhuSuFei.text))]
                ),
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.JingShenSunHaiFuWeiJin.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.JingShenSunHaiFuWeiJin, "checked", $$v)
                      },
                      expression: "JingShenSunHaiFuWeiJin.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.JingShenSunHaiFuWeiJin.text))]
                ),
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.WuGongFei.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.WuGongFei, "checked", $$v)
                      },
                      expression: "WuGongFei.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.WuGongFei.text))]
                ),
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.ZhuYuanHuLiFei.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.ZhuYuanHuLiFei, "checked", $$v)
                      },
                      expression: "ZhuYuanHuLiFei.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.ZhuYuanHuLiFei.text))]
                ),
                _c(
                  "van-checkbox",
                  {
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.ChuYuanHuLiFei.checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.ChuYuanHuLiFei, "checked", $$v)
                      },
                      expression: "ChuYuanHuLiFei.checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.ChuYuanHuLiFei.text))]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.SiWangPeiChangJin.checked,
                  expression: "SiWangPeiChangJin.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c("div", { staticClass: "project-title" }, [
                _c("div", { staticClass: "project-title-name" }, [
                  _vm._v(_vm._s(_vm.SiWangPeiChangJin.text)),
                ]),
                _c("div", { staticClass: "project-title-input-box" }, [
                  _vm._v(" 计 "),
                  _c(
                    "div",
                    {
                      staticClass: "table-input-ji",
                      class:
                        _vm.keyboard === "SiWangPeiChangJinResult" && _vm.show
                          ? "van-number-keyboard-input-select"
                          : "",
                      on: {
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          _vm.resultInputFlag = true
                          _vm.show = true
                          _vm.extraKey = "."
                          _vm.keyboard = "SiWangPeiChangJinResult"
                          _vm.setZero("SiWangPeiChangJinResult")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.SiWangPeiChangJinResult !== "",
                              expression: "SiWangPeiChangJinResult !== ''",
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.SiWangPeiChangJinResult)
                                      .wan > 0,
                                  expression:
                                    "yuanToWan(SiWangPeiChangJinResult).wan > 0",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.yuanToWan(_vm.SiWangPeiChangJinResult)
                                      .wan
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.SiWangPeiChangJinResult)
                                      .wan > 0,
                                  expression:
                                    "yuanToWan(SiWangPeiChangJinResult).wan > 0",
                                },
                              ],
                              staticClass: "table-input-ji-wan",
                              staticStyle: { "font-size": "10px !important" },
                            },
                            [_vm._v("万")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.yuanToWan(_vm.SiWangPeiChangJinResult).yuan
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" 元 "),
                ]),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        _vm.SiWangPeiChangJinShow = !_vm.SiWangPeiChangJinShow
                      },
                    },
                  },
                  [
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.SiWangPeiChangJinShow === false,
                          expression: "SiWangPeiChangJinShow === false",
                        },
                      ],
                      attrs: { name: "arrow" },
                    }),
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.SiWangPeiChangJinShow === true,
                          expression: "SiWangPeiChangJinShow === true",
                        },
                      ],
                      attrs: { name: "arrow-down" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.SiWangPeiChangJinShow === true,
                      expression: "SiWangPeiChangJinShow === true",
                    },
                  ],
                  staticClass: "project-content",
                },
                [
                  _c(
                    "div",
                    { staticClass: "box", staticStyle: { padding: "10px 0" } },
                    [
                      _vm.address === "江苏"
                        ? _c("span", [
                            _vm._v("( "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#0099ff",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openErrorCorrectionDialog(
                                      "全省居民人均工资性收入",
                                      "元",
                                      _vm.SiWangPeiChangJin.params.income
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.SiWangPeiChangJin.params.income) +
                                    "元"
                                ),
                              ]
                            ),
                            _vm._v(" + "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#0099ff",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openErrorCorrectionDialog(
                                      "全省居民人均经营净收入",
                                      "元",
                                      _vm.SiWangPeiChangJin.params.income2
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.SiWangPeiChangJin.params.income2) +
                                    "元"
                                ),
                              ]
                            ),
                            _vm._v(")*"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#0099ff",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openErrorCorrectionDialog(
                                      "全省平均负担系数",
                                      "元",
                                      _vm.SiWangPeiChangJin.params.coefficient
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.SiWangPeiChangJin.params.coefficient
                                  ) + " "
                                ),
                              ]
                            ),
                            _vm._v(
                              "*" +
                                _vm._s(_vm.SiWangPeiChangJin.params.year) +
                                "年"
                            ),
                          ])
                        : _vm._e(),
                      _vm.address !== "江苏"
                        ? _c("span", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#0099ff",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openErrorCorrectionDialog(
                                      "人均可支配收入",
                                      "元",
                                      _vm.SiWangPeiChangJin.params.income
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.SiWangPeiChangJin.params.income) +
                                    "元"
                                ),
                              ]
                            ),
                            _vm._v(
                              " *" +
                                _vm._s(_vm.SiWangPeiChangJin.params.year) +
                                "年"
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.SangZangFei.checked,
                  expression: "SangZangFei.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c("div", { staticClass: "project-title" }, [
                _c("div", { staticClass: "project-title-name" }, [
                  _vm._v(_vm._s(_vm.SangZangFei.text)),
                ]),
                _c("div", { staticClass: "project-title-input-box" }, [
                  _vm._v(" 计 "),
                  _c(
                    "div",
                    {
                      staticClass: "table-input-ji",
                      class:
                        _vm.keyboard === "SangZangFeiResult" && _vm.show
                          ? "van-number-keyboard-input-select"
                          : "",
                      on: {
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          _vm.resultInputFlag = true
                          _vm.show = true
                          _vm.extraKey = "."
                          _vm.keyboard = "SangZangFeiResult"
                          _vm.setZero("SangZangFeiResult")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.SangZangFeiResult !== "",
                              expression: "SangZangFeiResult !== ''",
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.SangZangFeiResult).wan >
                                    0,
                                  expression:
                                    "yuanToWan(SangZangFeiResult).wan > 0",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.yuanToWan(_vm.SangZangFeiResult).wan
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.SangZangFeiResult).wan >
                                    0,
                                  expression:
                                    "yuanToWan(SangZangFeiResult).wan > 0",
                                },
                              ],
                              staticClass: "table-input-ji-wan",
                              staticStyle: { "font-size": "10px !important" },
                            },
                            [_vm._v("万")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.yuanToWan(_vm.SangZangFeiResult).yuan)
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" 元 "),
                ]),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        _vm.SangZangFeiShow = !_vm.SangZangFeiShow
                      },
                    },
                  },
                  [
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.SangZangFeiShow === false,
                          expression: "SangZangFeiShow === false",
                        },
                      ],
                      attrs: { name: "arrow" },
                    }),
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.SangZangFeiShow === true,
                          expression: "SangZangFeiShow === true",
                        },
                      ],
                      attrs: { name: "arrow-down" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.SangZangFeiShow === true,
                      expression: "SangZangFeiShow === true",
                    },
                  ],
                  staticClass: "project-content",
                },
                [
                  _c("div", { staticClass: "box" }, [
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("industry-selector", {
                          attrs: {
                            address: _vm.SangZangFei.params.address,
                            industry: _vm.SangZangFei.params.industry,
                            time: _vm.SangZangFei.params.time,
                          },
                          on: {
                            upIndustry: _vm.upIndustry,
                            handleSelectIndustry: _vm.handleSelectIndustry,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "box", staticStyle: { padding: "10px 0" } },
                    [
                      _c("span", [
                        _vm._v("职工年平均工资"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.SangZangFei.params.money,
                              expression: "SangZangFei.params.money",
                            },
                          ],
                          staticClass: "table-input",
                          class:
                            _vm.keyboard === "SangZangFei.params.money" &&
                            _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: { value: _vm.SangZangFei.params.money },
                          on: {
                            change: function ($event) {
                              return _vm.addIncome("SangZangFei")
                            },
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "SangZangFei.params.money"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.SangZangFei.params,
                                "money",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("元/12个月*6个月"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.BeiFuYangRenShengHuoFei.checked,
                  expression: "BeiFuYangRenShengHuoFei.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c("div", { staticClass: "project-title" }, [
                _c("div", { staticClass: "project-title-name" }, [
                  _vm._v(" " + _vm._s(_vm.BeiFuYangRenShengHuoFei.text) + " "),
                ]),
                _c("div", { staticClass: "project-title-input-box" }, [
                  _vm._v(" 计 "),
                  _c(
                    "div",
                    {
                      staticClass: "table-input-ji",
                      class:
                        _vm.keyboard === "BeiFuYangRenShengHuoFeiResult" &&
                        _vm.show
                          ? "van-number-keyboard-input-select"
                          : "",
                      on: {
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          _vm.resultInputFlag = true
                          _vm.show = true
                          _vm.extraKey = "."
                          _vm.keyboard = "BeiFuYangRenShengHuoFeiResult"
                          _vm.setZero("BeiFuYangRenShengHuoFeiResult")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.BeiFuYangRenShengHuoFeiResult !== "",
                              expression:
                                "BeiFuYangRenShengHuoFeiResult !== ''",
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(
                                      _vm.BeiFuYangRenShengHuoFeiResult
                                    ).wan > 0,
                                  expression:
                                    "yuanToWan(BeiFuYangRenShengHuoFeiResult).wan > 0",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.yuanToWan(
                                      _vm.BeiFuYangRenShengHuoFeiResult
                                    ).wan
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(
                                      _vm.BeiFuYangRenShengHuoFeiResult
                                    ).wan > 0,
                                  expression:
                                    "yuanToWan(BeiFuYangRenShengHuoFeiResult).wan > 0",
                                },
                              ],
                              staticClass: "table-input-ji-wan",
                              staticStyle: { "font-size": "10px !important" },
                            },
                            [_vm._v("万")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.yuanToWan(_vm.BeiFuYangRenShengHuoFeiResult)
                                  .yuan
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" 元 "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.BeiFuYangRenShengHuoFei.expenditure,
                          expression: "BeiFuYangRenShengHuoFei.expenditure",
                        },
                      ],
                      staticStyle: { color: "#ff9900" },
                    },
                    [
                      _vm._v(
                        "(=" +
                          _vm._s(_vm.BeiFuYangRenShengHuoFei.expenditure) +
                          "元超出人均年消费支出)"
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        _vm.BeiFuYangRenShengHuoFeiShow =
                          !_vm.BeiFuYangRenShengHuoFeiShow
                      },
                    },
                  },
                  [
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.BeiFuYangRenShengHuoFeiShow === false,
                          expression: "BeiFuYangRenShengHuoFeiShow === false",
                        },
                      ],
                      attrs: { name: "arrow" },
                    }),
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.BeiFuYangRenShengHuoFeiShow === true,
                          expression: "BeiFuYangRenShengHuoFeiShow === true",
                        },
                      ],
                      attrs: { name: "arrow-down" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.BeiFuYangRenShengHuoFeiShow === true,
                      expression: "BeiFuYangRenShengHuoFeiShow === true",
                    },
                  ],
                  staticClass: "project-content",
                },
                [
                  _c(
                    "div",
                    { staticClass: "box2", staticStyle: { padding: "10px 0" } },
                    [
                      _c("div", [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.BeiFuYangRenShengHuoFei.params.checked1,
                              expression:
                                "BeiFuYangRenShengHuoFei.params.checked1",
                            },
                          ],
                          staticClass: "iconfont icon-xuanzhong",
                          on: {
                            click: function ($event) {
                              _vm.BeiFuYangRenShengHuoFei.params.checked1 = false
                            },
                          },
                        }),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.BeiFuYangRenShengHuoFei.params.checked1 ===
                                false,
                              expression:
                                "BeiFuYangRenShengHuoFei.params.checked1 === false",
                            },
                          ],
                          staticClass: "iconfont icon-kongbai",
                          on: {
                            click: function ($event) {
                              _vm.BeiFuYangRenShengHuoFei.params.checked1 = true
                            },
                          },
                        }),
                        _vm._v(" 未成年 "),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "margin-left": "10px" } },
                        _vm._l(
                          _vm.BeiFuYangRenShengHuoFei.params.list1,
                          function (item, i) {
                            return _c("div", { key: i }, [
                              _vm._v(" 被抚养人" + _vm._s(i + 1) + "：年龄"),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.year,
                                    expression: "item.year",
                                  },
                                ],
                                staticClass: "table-input table-input-year",
                                class:
                                  _vm.keyboard ===
                                    `${
                                      "BeiFuYangRenShengHuoFei.params.list1." +
                                      i +
                                      ".year"
                                    }` && _vm.show
                                    ? "van-number-keyboard-input-select"
                                    : "",
                                attrs: { readonly: "", clickable: "" },
                                domProps: { value: item.year },
                                on: {
                                  touchstart: function ($event) {
                                    $event.stopPropagation()
                                    _vm.resultInputFlag = false
                                    _vm.show = true
                                    _vm.extraKey = ""
                                    _vm.keyboard = `${
                                      "BeiFuYangRenShengHuoFei.params.list1." +
                                      i +
                                      ".year"
                                    }`
                                    _vm.titleObj = {
                                      key: `${
                                        "BeiFuYangRenShengHuoFei.params.list1." +
                                        i +
                                        ".year"
                                      }`,
                                      no: "请输入被抚养人年龄",
                                      yes: "被抚养人年龄###岁",
                                    }
                                    _vm.setKeyboardTitle(
                                      item.year,
                                      `${
                                        "BeiFuYangRenShengHuoFei.params.list1." +
                                        i +
                                        ".year"
                                      }`
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "year", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v("岁"),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.month,
                                    expression: "item.month",
                                  },
                                ],
                                staticClass: "table-input table-input-year",
                                class:
                                  _vm.keyboard ===
                                    `${
                                      "BeiFuYangRenShengHuoFei.params.list1." +
                                      i +
                                      ".month"
                                    }` && _vm.show
                                    ? "van-number-keyboard-input-select"
                                    : "",
                                attrs: { readonly: "", clickable: "" },
                                domProps: { value: item.month },
                                on: {
                                  touchstart: function ($event) {
                                    $event.stopPropagation()
                                    _vm.resultInputFlag = false
                                    _vm.show = true
                                    _vm.extraKey = ""
                                    _vm.keyboard = `${
                                      "BeiFuYangRenShengHuoFei.params.list1." +
                                      i +
                                      ".month"
                                    }`
                                    _vm.titleObj = {
                                      key: `${
                                        "BeiFuYangRenShengHuoFei.params.list1." +
                                        i +
                                        ".month"
                                      }`,
                                      no: "请输入被抚养人年龄",
                                      yes: "###个月",
                                    }
                                    _vm.setKeyboardTitle(
                                      item.month,
                                      `${
                                        "BeiFuYangRenShengHuoFei.params.list1." +
                                        i +
                                        ".month"
                                      }`
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "month", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v("个月，抚养人人数"),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.num,
                                    expression: "item.num",
                                  },
                                ],
                                staticClass: "table-input table-input-num",
                                class:
                                  _vm.keyboard ===
                                    `${
                                      "BeiFuYangRenShengHuoFei.params.list1." +
                                      i +
                                      ".num"
                                    }` && _vm.show
                                    ? "van-number-keyboard-input-select"
                                    : "",
                                attrs: { readonly: "", clickable: "" },
                                domProps: { value: item.num },
                                on: {
                                  touchstart: function ($event) {
                                    $event.stopPropagation()
                                    _vm.resultInputFlag = false
                                    _vm.show = true
                                    _vm.extraKey = ""
                                    _vm.keyboard = `${
                                      "BeiFuYangRenShengHuoFei.params.list1." +
                                      i +
                                      ".num"
                                    }`
                                    _vm.titleObj = {
                                      key: `${
                                        "BeiFuYangRenShengHuoFei.params.list1." +
                                        i +
                                        ".num"
                                      }`,
                                      no: "请输入抚养人人数",
                                      yes: "抚养人人数###人",
                                    }
                                    _vm.setKeyboardTitle(
                                      item.num,
                                      `${
                                        "BeiFuYangRenShengHuoFei.params.list1." +
                                        i +
                                        ".num"
                                      }`
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "num", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v("人 "),
                              item.result
                                ? _c("span", [
                                    _vm._v(" ，计"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#ed3f3e" } },
                                      [_vm._v(_vm._s(item.result))]
                                    ),
                                    _vm._v("元"),
                                    item.expenditure
                                      ? _c("span", [
                                          _vm._v(
                                            "(超标" +
                                              _vm._s(item.expenditure) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              i === 0
                                ? _c("i", {
                                    staticClass: "iconfont icon-tianjia1",
                                    staticStyle: {
                                      color: "#569af9",
                                      cursor: "pointer",
                                      "margin-left": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.BeiFuYangRenShengHuoFeiAdd(1)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              i > 0
                                ? _c("i", {
                                    staticClass: "iconfont icon-shanchu1",
                                    staticStyle: {
                                      color: "#569af9",
                                      cursor: "pointer",
                                      "margin-left": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.BeiFuYangRenShengHuoFeiDel(
                                          1,
                                          i
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "box2", staticStyle: { padding: "10px 0" } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.BeiFuYangRenShengHuoFei.params.checked2,
                              expression:
                                "BeiFuYangRenShengHuoFei.params.checked2",
                            },
                          ],
                          staticClass: "iconfont icon-xuanzhong",
                          on: {
                            click: function ($event) {
                              _vm.BeiFuYangRenShengHuoFei.params.checked2 = false
                            },
                          },
                        }),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.BeiFuYangRenShengHuoFei.params.checked2 ===
                                false,
                              expression:
                                "BeiFuYangRenShengHuoFei.params.checked2 === false",
                            },
                          ],
                          staticClass: "iconfont icon-kongbai",
                          on: {
                            click: function ($event) {
                              _vm.BeiFuYangRenShengHuoFei.params.checked2 = true
                            },
                          },
                        }),
                        _c("div", [
                          _c("div", [_vm._v("无劳动能力又无")]),
                          _c("div", [_vm._v("其他生活来源的")]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "margin-left": "10px" } },
                        _vm._l(
                          _vm.BeiFuYangRenShengHuoFei.params.list2,
                          function (item, i) {
                            return _c("div", { key: i }, [
                              _vm._v(
                                " 被抚养人" + _vm._s(i + 1) + "：抚养人人数"
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.num,
                                    expression: "item.num",
                                  },
                                ],
                                staticClass: "table-input table-input-num",
                                class:
                                  _vm.keyboard ===
                                    `${
                                      "BeiFuYangRenShengHuoFei.params.list2." +
                                      i +
                                      ".num"
                                    }` && _vm.show
                                    ? "van-number-keyboard-input-select"
                                    : "",
                                attrs: { readonly: "", clickable: "" },
                                domProps: { value: item.num },
                                on: {
                                  touchstart: function ($event) {
                                    $event.stopPropagation()
                                    _vm.resultInputFlag = false
                                    _vm.show = true
                                    _vm.extraKey = ""
                                    _vm.keyboard = `${
                                      "BeiFuYangRenShengHuoFei.params.list2." +
                                      i +
                                      ".num"
                                    }`
                                    _vm.titleObj = {
                                      key: `${
                                        "BeiFuYangRenShengHuoFei.params.list2." +
                                        i +
                                        ".num"
                                      }`,
                                      no: "请输入抚养人人数",
                                      yes: "抚养人人数###人",
                                    }
                                    _vm.setKeyboardTitle(
                                      item.num,
                                      `${
                                        "BeiFuYangRenShengHuoFei.params.list2." +
                                        i +
                                        ".num"
                                      }`
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "num", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v("人 "),
                              item.result
                                ? _c("span", [
                                    _vm._v(" ，计"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#ed3f3e" } },
                                      [_vm._v(_vm._s(item.result))]
                                    ),
                                    _vm._v("元"),
                                    item.expenditure
                                      ? _c("span", [
                                          _vm._v(
                                            "(超标" +
                                              _vm._s(item.expenditure) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              i === 0
                                ? _c("i", {
                                    staticClass: "iconfont icon-tianjia1",
                                    staticStyle: {
                                      color: "#569af9",
                                      cursor: "pointer",
                                      "margin-left": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.BeiFuYangRenShengHuoFeiAdd(2)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              i > 0
                                ? _c("i", {
                                    staticClass: "iconfont icon-shanchu1",
                                    staticStyle: {
                                      color: "#569af9",
                                      cursor: "pointer",
                                      "margin-left": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.BeiFuYangRenShengHuoFeiDel(
                                          2,
                                          i
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "box2", staticStyle: { padding: "10px 0" } },
                    [
                      _c("div", [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.BeiFuYangRenShengHuoFei.params.checked3,
                              expression:
                                "BeiFuYangRenShengHuoFei.params.checked3",
                            },
                          ],
                          staticClass: "iconfont icon-xuanzhong",
                          on: {
                            click: function ($event) {
                              _vm.BeiFuYangRenShengHuoFei.params.checked3 = false
                            },
                          },
                        }),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.BeiFuYangRenShengHuoFei.params.checked3 ===
                                false,
                              expression:
                                "BeiFuYangRenShengHuoFei.params.checked3 === false",
                            },
                          ],
                          staticClass: "iconfont icon-kongbai",
                          on: {
                            click: function ($event) {
                              _vm.BeiFuYangRenShengHuoFei.params.checked3 = true
                            },
                          },
                        }),
                        _vm._v(" 60岁以上 "),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "margin-left": "10px" } },
                        _vm._l(
                          _vm.BeiFuYangRenShengHuoFei.params.list3,
                          function (item, i) {
                            return _c("div", { key: i }, [
                              _vm._v(" 被抚养人" + _vm._s(i + 1) + "：年龄"),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.year,
                                    expression: "item.year",
                                  },
                                ],
                                staticClass: "table-input table-input-year",
                                class:
                                  _vm.keyboard ===
                                    `${
                                      "BeiFuYangRenShengHuoFei.params.list3." +
                                      i +
                                      ".year"
                                    }` && _vm.show
                                    ? "van-number-keyboard-input-select"
                                    : "",
                                attrs: { readonly: "", clickable: "" },
                                domProps: { value: item.year },
                                on: {
                                  touchstart: function ($event) {
                                    $event.stopPropagation()
                                    _vm.resultInputFlag = false
                                    _vm.show = true
                                    _vm.extraKey = ""
                                    _vm.keyboard = `${
                                      "BeiFuYangRenShengHuoFei.params.list3." +
                                      i +
                                      ".year"
                                    }`
                                    _vm.titleObj = {
                                      key: `${
                                        "BeiFuYangRenShengHuoFei.params.list3." +
                                        i +
                                        ".year"
                                      }`,
                                      no: "请输入被抚养人年龄",
                                      yes: "被抚养人年龄###岁",
                                    }
                                    _vm.setKeyboardTitle(
                                      item.year,
                                      `${
                                        "BeiFuYangRenShengHuoFei.params.list3." +
                                        i +
                                        ".year"
                                      }`
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "year", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v("岁"),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.month,
                                    expression: "item.month",
                                  },
                                ],
                                staticClass: "table-input table-input-year",
                                class:
                                  _vm.keyboard ===
                                    `${
                                      "BeiFuYangRenShengHuoFei.params.list3." +
                                      i +
                                      ".month"
                                    }` && _vm.show
                                    ? "van-number-keyboard-input-select"
                                    : "",
                                attrs: { readonly: "", clickable: "" },
                                domProps: { value: item.month },
                                on: {
                                  touchstart: function ($event) {
                                    $event.stopPropagation()
                                    _vm.resultInputFlag = false
                                    _vm.show = true
                                    _vm.extraKey = ""
                                    _vm.keyboard = `${
                                      "BeiFuYangRenShengHuoFei.params.list3." +
                                      i +
                                      ".month"
                                    }`
                                    _vm.titleObj = {
                                      key: `${
                                        "BeiFuYangRenShengHuoFei.params.list3." +
                                        i +
                                        ".month"
                                      }`,
                                      no: "请输入被抚养人年龄",
                                      yes: "###个月",
                                    }
                                    _vm.setKeyboardTitle(
                                      item.month,
                                      `${
                                        "BeiFuYangRenShengHuoFei.params.list3." +
                                        i +
                                        ".month"
                                      }`
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "month", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v("个月，抚养人人数"),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.num,
                                    expression: "item.num",
                                  },
                                ],
                                staticClass: "table-input table-input-num",
                                class:
                                  _vm.keyboard ===
                                    `${
                                      "BeiFuYangRenShengHuoFei.params.list3." +
                                      i +
                                      ".num"
                                    }` && _vm.show
                                    ? "van-number-keyboard-input-select"
                                    : "",
                                attrs: { readonly: "", clickable: "" },
                                domProps: { value: item.num },
                                on: {
                                  touchstart: function ($event) {
                                    $event.stopPropagation()
                                    _vm.resultInputFlag = false
                                    _vm.show = true
                                    _vm.extraKey = ""
                                    _vm.keyboard = `${
                                      "BeiFuYangRenShengHuoFei.params.list3." +
                                      i +
                                      ".num"
                                    }`
                                    _vm.titleObj = {
                                      key: `${
                                        "BeiFuYangRenShengHuoFei.params.list3." +
                                        i +
                                        ".num"
                                      }`,
                                      no: "请输入抚养人人数",
                                      yes: "抚养人人数###人",
                                    }
                                    _vm.setKeyboardTitle(
                                      item.num,
                                      `${
                                        "BeiFuYangRenShengHuoFei.params.list3." +
                                        i +
                                        ".num"
                                      }`
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "num", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v("人 "),
                              item.result
                                ? _c("span", [
                                    _vm._v(" ，计"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#ed3f3e" } },
                                      [_vm._v(_vm._s(item.result))]
                                    ),
                                    _vm._v("元"),
                                    item.expenditure
                                      ? _c("span", [
                                          _vm._v(
                                            "(超标" +
                                              _vm._s(item.expenditure) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              i === 0
                                ? _c("i", {
                                    staticClass: "iconfont icon-tianjia1",
                                    staticStyle: {
                                      color: "#569af9",
                                      cursor: "pointer",
                                      "margin-left": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.BeiFuYangRenShengHuoFeiAdd(3)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              i > 0
                                ? _c("i", {
                                    staticClass: "iconfont icon-shanchu1",
                                    staticStyle: {
                                      color: "#569af9",
                                      cursor: "pointer",
                                      "margin-left": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.BeiFuYangRenShengHuoFeiDel(
                                          3,
                                          i
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "box2", staticStyle: { padding: "10px 0" } },
                    [
                      _c("div", [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.BeiFuYangRenShengHuoFei.params.checked4,
                              expression:
                                "BeiFuYangRenShengHuoFei.params.checked4",
                            },
                          ],
                          staticClass: "iconfont icon-xuanzhong",
                          on: {
                            click: function ($event) {
                              _vm.BeiFuYangRenShengHuoFei.params.checked4 = false
                            },
                          },
                        }),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.BeiFuYangRenShengHuoFei.params.checked4 ===
                                false,
                              expression:
                                "BeiFuYangRenShengHuoFei.params.checked4 === false",
                            },
                          ],
                          staticClass: "iconfont icon-kongbai",
                          on: {
                            click: function ($event) {
                              _vm.BeiFuYangRenShengHuoFei.params.checked4 = true
                            },
                          },
                        }),
                        _vm._v(" 75岁以上 "),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "margin-left": "10px" } },
                        _vm._l(
                          _vm.BeiFuYangRenShengHuoFei.params.list4,
                          function (item, i) {
                            return _c("div", { key: i }, [
                              _vm._v(
                                " 被抚养人" + _vm._s(i + 1) + "：抚养人人数"
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.num,
                                    expression: "item.num",
                                  },
                                ],
                                staticClass: "table-input table-input-num",
                                class:
                                  _vm.keyboard ===
                                    `${
                                      "BeiFuYangRenShengHuoFei.params.list4." +
                                      i +
                                      ".num"
                                    }` && _vm.show
                                    ? "van-number-keyboard-input-select"
                                    : "",
                                attrs: { readonly: "", clickable: "" },
                                domProps: { value: item.num },
                                on: {
                                  touchstart: function ($event) {
                                    $event.stopPropagation()
                                    _vm.resultInputFlag = false
                                    _vm.show = true
                                    _vm.extraKey = ""
                                    _vm.keyboard = `${
                                      "BeiFuYangRenShengHuoFei.params.list4." +
                                      i +
                                      ".num"
                                    }`
                                    _vm.titleObj = {
                                      key: `${
                                        "BeiFuYangRenShengHuoFei.params.list4." +
                                        i +
                                        ".num"
                                      }`,
                                      no: "请输入抚养人人数",
                                      yes: "抚养人人数###人",
                                    }
                                    _vm.setKeyboardTitle(
                                      item.num,
                                      `${
                                        "BeiFuYangRenShengHuoFei.params.list4." +
                                        i +
                                        ".num"
                                      }`
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "num", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v("人 "),
                              item.result
                                ? _c("span", [
                                    _vm._v(" ，计"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#ed3f3e" } },
                                      [_vm._v(_vm._s(item.result))]
                                    ),
                                    _vm._v("元"),
                                    item.expenditure
                                      ? _c("span", [
                                          _vm._v(
                                            "(超标" +
                                              _vm._s(item.expenditure) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              i === 0
                                ? _c("i", {
                                    staticClass: "iconfont icon-tianjia1",
                                    staticStyle: {
                                      color: "#569af9",
                                      cursor: "pointer",
                                      "margin-left": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.BeiFuYangRenShengHuoFeiAdd(4)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              i > 0
                                ? _c("i", {
                                    staticClass: "iconfont icon-shanchu1",
                                    staticStyle: {
                                      color: "#569af9",
                                      cursor: "pointer",
                                      "margin-left": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.BeiFuYangRenShengHuoFeiDel(
                                          4,
                                          i
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "box2",
                      staticStyle: {
                        padding: "10px 0",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.time !== "",
                                expression: "time !== ''",
                              },
                            ],
                            staticClass: "box-title",
                            staticStyle: { color: "#999" },
                          },
                          [_vm._v(_vm._s(parseInt(_vm.time) - 1))]
                        ),
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v(_vm._s(_vm.address)),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("人均年消费性支出"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.BeiFuYangRenShengHuoFei.params.income,
                                expression:
                                  "BeiFuYangRenShengHuoFei.params.income",
                              },
                            ],
                            staticClass: "table-input",
                            class:
                              _vm.keyboard ===
                                "BeiFuYangRenShengHuoFei.params.income" &&
                              _vm.show
                                ? "van-number-keyboard-input-select"
                                : "",
                            staticStyle: { height: "42px" },
                            attrs: { readonly: "", clickable: "" },
                            domProps: {
                              value: _vm.BeiFuYangRenShengHuoFei.params.income,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.addIncome("BeiFuYangRenShengHuoFei")
                              },
                              touchstart: function ($event) {
                                $event.stopPropagation()
                                _vm.resultInputFlag = false
                                _vm.show = true
                                _vm.extraKey = "."
                                _vm.keyboard =
                                  "BeiFuYangRenShengHuoFei.params.income"
                                _vm.setZero(
                                  "BeiFuYangRenShengHuoFei.params.income"
                                )
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.BeiFuYangRenShengHuoFei.params,
                                  "income",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" 元 "),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.YiLiaoFei.checked,
                  expression: "YiLiaoFei.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c(
                "div",
                {
                  staticClass: "project-title",
                  on: {
                    click: function ($event) {
                      _vm.YiLiaoFei.show = !_vm.YiLiaoFei.show
                    },
                  },
                },
                [
                  _c("div", { staticClass: "project-title-name" }, [
                    _vm._v(_vm._s(_vm.YiLiaoFei.text)),
                  ]),
                  _c("div", { staticClass: "project-title-input-box" }, [
                    _vm._v(" 计 "),
                    _c(
                      "div",
                      {
                        staticClass: "table-input-ji",
                        class:
                          _vm.keyboard === "YiLiaoFei.params.money" && _vm.show
                            ? "van-number-keyboard-input-select"
                            : "",
                        on: {
                          touchstart: function ($event) {
                            $event.stopPropagation()
                            _vm.resultInputFlag = true
                            _vm.show = true
                            _vm.extraKey = "."
                            _vm.keyboard = "YiLiaoFei.params.money"
                            _vm.setZero("YiLiaoFei.params.money")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.YiLiaoFei.params.money != 0,
                                expression: "YiLiaoFei.params.money != 0",
                              },
                            ],
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.yuanToWan(_vm.YiLiaoFei.params.money)
                                        .wan > 0,
                                    expression:
                                      "yuanToWan(YiLiaoFei.params.money).wan > 0",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.yuanToWan(_vm.YiLiaoFei.params.money)
                                        .wan
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.yuanToWan(_vm.YiLiaoFei.params.money)
                                        .wan > 0,
                                    expression:
                                      "yuanToWan(YiLiaoFei.params.money).wan > 0",
                                  },
                                ],
                                staticClass: "table-input-ji-wan",
                                staticStyle: { "font-size": "10px !important" },
                              },
                              [_vm._v("万")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.yuanToWan(_vm.YiLiaoFei.params.money).yuan
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" 元 "),
                  ]),
                  _c("div"),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.JiaoTongFei.checked,
                  expression: "JiaoTongFei.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c(
                "div",
                {
                  staticClass: "project-title",
                  on: {
                    click: function ($event) {
                      _vm.JiaoTongFei.show = !_vm.JiaoTongFei.show
                    },
                  },
                },
                [
                  _c("div", { staticClass: "project-title-name" }, [
                    _vm._v(_vm._s(_vm.JiaoTongFei.text)),
                  ]),
                  _c("div", { staticClass: "project-title-input-box" }, [
                    _vm._v(" 计 "),
                    _c(
                      "div",
                      {
                        staticClass: "table-input-ji",
                        class:
                          _vm.keyboard === "JiaoTongFei.params.money" &&
                          _vm.show
                            ? "van-number-keyboard-input-select"
                            : "",
                        on: {
                          touchstart: function ($event) {
                            $event.stopPropagation()
                            _vm.resultInputFlag = true
                            _vm.show = true
                            _vm.extraKey = "."
                            _vm.keyboard = "JiaoTongFei.params.money"
                            _vm.setZero("JiaoTongFei.params.money")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.JiaoTongFei.params.money != 0,
                                expression: "JiaoTongFei.params.money != 0",
                              },
                            ],
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.yuanToWan(
                                        _vm.JiaoTongFei.params.money
                                      ).wan > 0,
                                    expression:
                                      "yuanToWan(JiaoTongFei.params.money).wan > 0",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.yuanToWan(
                                        _vm.JiaoTongFei.params.money
                                      ).wan
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.yuanToWan(
                                        _vm.JiaoTongFei.params.money
                                      ).wan > 0,
                                    expression:
                                      "yuanToWan(JiaoTongFei.params.money).wan > 0",
                                  },
                                ],
                                staticClass: "table-input-ji-wan",
                                staticStyle: { "font-size": "10px !important" },
                              },
                              [_vm._v("万")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.yuanToWan(_vm.JiaoTongFei.params.money)
                                    .yuan
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" 元 "),
                  ]),
                  _c("div"),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ZhuSuFei.checked,
                  expression: "ZhuSuFei.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c(
                "div",
                {
                  staticClass: "project-title",
                  on: {
                    click: function ($event) {
                      _vm.ZhuSuFei.show = !_vm.ZhuSuFei.show
                    },
                  },
                },
                [
                  _c("div", { staticClass: "project-title-name" }, [
                    _vm._v(_vm._s(_vm.ZhuSuFei.text)),
                  ]),
                  _c("div", { staticClass: "project-title-input-box" }, [
                    _vm._v(" 计 "),
                    _c(
                      "div",
                      {
                        staticClass: "table-input-ji",
                        class:
                          _vm.keyboard === "ZhuSuFei.params.money" && _vm.show
                            ? "van-number-keyboard-input-select"
                            : "",
                        on: {
                          touchstart: function ($event) {
                            $event.stopPropagation()
                            _vm.resultInputFlag = true
                            _vm.show = true
                            _vm.extraKey = "."
                            _vm.keyboard = "ZhuSuFei.params.money"
                            _vm.setZero("ZhuSuFei.params.money")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ZhuSuFei.params.money != 0,
                                expression: "ZhuSuFei.params.money != 0",
                              },
                            ],
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.yuanToWan(_vm.ZhuSuFei.params.money)
                                        .wan > 0,
                                    expression:
                                      "yuanToWan(ZhuSuFei.params.money).wan > 0",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.yuanToWan(_vm.ZhuSuFei.params.money)
                                        .wan
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.yuanToWan(_vm.ZhuSuFei.params.money)
                                        .wan > 0,
                                    expression:
                                      "yuanToWan(ZhuSuFei.params.money).wan > 0",
                                  },
                                ],
                                staticClass: "table-input-ji-wan",
                                staticStyle: { "font-size": "10px !important" },
                              },
                              [_vm._v("万")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.yuanToWan(_vm.ZhuSuFei.params.money).yuan
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" 元 "),
                  ]),
                  _c("div"),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.JingShenSunHaiFuWeiJin.checked,
                  expression: "JingShenSunHaiFuWeiJin.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c(
                "div",
                {
                  staticClass: "project-title",
                  on: {
                    click: function ($event) {
                      _vm.JingShenSunHaiFuWeiJin.show =
                        !_vm.JingShenSunHaiFuWeiJin.show
                    },
                  },
                },
                [
                  _c("div", { staticClass: "project-title-name" }, [
                    _vm._v(" " + _vm._s(_vm.JingShenSunHaiFuWeiJin.text) + " "),
                  ]),
                  _c("div", { staticClass: "project-title-input-box" }, [
                    _vm._v(" 计 "),
                    _c(
                      "div",
                      {
                        staticClass: "table-input-ji",
                        class:
                          _vm.keyboard ===
                            "JingShenSunHaiFuWeiJin.params.money" && _vm.show
                            ? "van-number-keyboard-input-select"
                            : "",
                        on: {
                          touchstart: function ($event) {
                            $event.stopPropagation()
                            _vm.resultInputFlag = true
                            _vm.show = true
                            _vm.extraKey = "."
                            _vm.keyboard = "JingShenSunHaiFuWeiJin.params.money"
                            _vm.setZero("JingShenSunHaiFuWeiJin.params.money")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.JingShenSunHaiFuWeiJin.params.money != 0,
                                expression:
                                  "JingShenSunHaiFuWeiJin.params.money != 0",
                              },
                            ],
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.yuanToWan(
                                        _vm.JingShenSunHaiFuWeiJin.params.money
                                      ).wan > 0,
                                    expression:
                                      "yuanToWan(JingShenSunHaiFuWeiJin.params.money).wan > 0",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.yuanToWan(
                                        _vm.JingShenSunHaiFuWeiJin.params.money
                                      ).wan
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.yuanToWan(
                                        _vm.JingShenSunHaiFuWeiJin.params.money
                                      ).wan > 0,
                                    expression:
                                      "yuanToWan(JingShenSunHaiFuWeiJin.params.money).wan > 0",
                                  },
                                ],
                                staticClass: "table-input-ji-wan",
                                staticStyle: { "font-size": "10px !important" },
                              },
                              [_vm._v("万")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.yuanToWan(
                                    _vm.JingShenSunHaiFuWeiJin.params.money
                                  ).yuan
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" 元 "),
                  ]),
                  _c("div"),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.WuGongFei.checked,
                  expression: "WuGongFei.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c("div", { staticClass: "project-title" }, [
                _c("div", { staticClass: "project-title-name" }, [
                  _vm._v(_vm._s(_vm.WuGongFei.text)),
                ]),
                _c("div", { staticClass: "project-title-input-box" }, [
                  _vm._v(" 计 "),
                  _c(
                    "div",
                    {
                      staticClass: "table-input-ji",
                      class:
                        _vm.keyboard === "WuGongFeiResult" && _vm.show
                          ? "van-number-keyboard-input-select"
                          : "",
                      on: {
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          _vm.resultInputFlag = true
                          _vm.show = true
                          _vm.extraKey = "."
                          _vm.keyboard = "WuGongFeiResult"
                          _vm.setZero("WuGongFeiResult")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.WuGongFeiResult !== "",
                              expression: "WuGongFeiResult !== ''",
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.WuGongFeiResult).wan > 0,
                                  expression:
                                    "yuanToWan(WuGongFeiResult).wan > 0",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.yuanToWan(_vm.WuGongFeiResult).wan
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.WuGongFeiResult).wan > 0,
                                  expression:
                                    "yuanToWan(WuGongFeiResult).wan > 0",
                                },
                              ],
                              staticClass: "table-input-ji-wan",
                              staticStyle: { "font-size": "10px !important" },
                            },
                            [_vm._v("万")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.yuanToWan(_vm.WuGongFeiResult).yuan)
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" 元 "),
                ]),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        _vm.WuGongFeiShow = !_vm.WuGongFeiShow
                      },
                    },
                  },
                  [
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.WuGongFeiShow === false,
                          expression: "WuGongFeiShow === false",
                        },
                      ],
                      attrs: { name: "arrow" },
                    }),
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.WuGongFeiShow === true,
                          expression: "WuGongFeiShow === true",
                        },
                      ],
                      attrs: { name: "arrow-down" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.WuGongFeiShow === true,
                      expression: "WuGongFeiShow === true",
                    },
                  ],
                  staticClass: "project-content",
                },
                [
                  _c(
                    "div",
                    { staticClass: "box", staticStyle: { padding: "10px 0" } },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.WuGongFei.params.checked === 1,
                            expression: "WuGongFei.params.checked === 1",
                          },
                        ],
                        staticClass: "iconfont icon-danxuan",
                        on: {
                          click: function ($event) {
                            _vm.WuGongFei.params.checked = 1
                          },
                        },
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.WuGongFei.params.checked !== 1,
                            expression: "WuGongFei.params.checked !== 1",
                          },
                        ],
                        staticClass: "iconfont icon-yuanxingweixuanzhong",
                        on: {
                          click: function ($event) {
                            _vm.WuGongFei.params.checked = 1
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v("有固定收入受害人工资"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.WuGongFei.params.list[0].money,
                              expression: "WuGongFei.params.list[0].money",
                            },
                          ],
                          staticClass: "table-input",
                          class:
                            _vm.keyboard === "WuGongFei.params.list.0.money" &&
                            _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.WuGongFei.params.list[0].money,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "WuGongFei.params.list.0.money"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.WuGongFei.params.list[0],
                                "money",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("元/天*误工天数"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.WuGongFei.params.list[0].day,
                              expression: "WuGongFei.params.list[0].day",
                            },
                          ],
                          staticClass: "table-input table-input-day",
                          class:
                            _vm.keyboard === "WuGongFei.params.list.0.day" &&
                            _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: { value: _vm.WuGongFei.params.list[0].day },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "WuGongFei.params.list.0.day"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.WuGongFei.params.list[0],
                                "day",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("天"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "box", staticStyle: { padding: "10px 0" } },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.WuGongFei.params.checked === 2,
                            expression: "WuGongFei.params.checked === 2",
                          },
                        ],
                        staticClass: "iconfont icon-danxuan",
                        on: {
                          click: function ($event) {
                            _vm.WuGongFei.params.checked = 2
                          },
                        },
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.WuGongFei.params.checked !== 2,
                            expression: "WuGongFei.params.checked !== 2",
                          },
                        ],
                        staticClass: "iconfont icon-yuanxingweixuanzhong",
                        on: {
                          click: function ($event) {
                            _vm.WuGongFei.params.checked = 2
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v(" 能证明 受害人最近三年平均收入"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.WuGongFei.params.list[1].money,
                              expression: "WuGongFei.params.list[1].money",
                            },
                          ],
                          staticClass: "table-input",
                          class:
                            _vm.keyboard === "WuGongFei.params.list.1.money" &&
                            _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.WuGongFei.params.list[1].money,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "WuGongFei.params.list.1.money"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.WuGongFei.params.list[1],
                                "money",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("元/天*误工天数"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.WuGongFei.params.list[1].day,
                              expression: "WuGongFei.params.list[1].day",
                            },
                          ],
                          staticClass: "table-input table-input-day",
                          class:
                            _vm.keyboard === "WuGongFei.params.list.1.day" &&
                            _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: { value: _vm.WuGongFei.params.list[1].day },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "WuGongFei.params.list.1.day"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.WuGongFei.params.list[1],
                                "day",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("天 "),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "box", staticStyle: { padding: "10px 0" } },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.WuGongFei.params.checked === 3,
                            expression: "WuGongFei.params.checked === 3",
                          },
                        ],
                        staticClass: "iconfont icon-danxuan",
                        on: {
                          click: function ($event) {
                            _vm.WuGongFei.params.checked = 3
                          },
                        },
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.WuGongFei.params.checked !== 3,
                            expression: "WuGongFei.params.checked !== 3",
                          },
                        ],
                        staticClass: "iconfont icon-yuanxingweixuanzhong",
                        on: {
                          click: function ($event) {
                            _vm.WuGongFei.params.checked = 3
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v("不能证明 相同或者相近行业上一年职工的平均工资"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.WuGongFei.params.list[2].money,
                              expression: "WuGongFei.params.list[2].money",
                            },
                          ],
                          staticClass: "table-input",
                          class:
                            _vm.keyboard === "WuGongFei.params.list.2.money" &&
                            _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.WuGongFei.params.list[2].money,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "WuGongFei.params.list.2.money"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.WuGongFei.params.list[2],
                                "money",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("元/天*误工天数"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.WuGongFei.params.list[2].day,
                              expression: "WuGongFei.params.list[2].day",
                            },
                          ],
                          staticClass: "table-input table-input-day",
                          class:
                            _vm.keyboard === "WuGongFei.params.list.2.day" &&
                            _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: { value: _vm.WuGongFei.params.list[2].day },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "WuGongFei.params.list.2.day"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.WuGongFei.params.list[2],
                                "day",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("天 "),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ZhuYuanHuLiFei.checked,
                  expression: "ZhuYuanHuLiFei.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c("div", { staticClass: "project-title" }, [
                _c("div", { staticClass: "project-title-name" }, [
                  _vm._v(_vm._s(_vm.ZhuYuanHuLiFei.text)),
                ]),
                _c("div", { staticClass: "project-title-input-box" }, [
                  _vm._v(" 计 "),
                  _c(
                    "div",
                    {
                      staticClass: "table-input-ji",
                      class:
                        _vm.keyboard === "ZhuYuanHuLiFeiResult" && _vm.show
                          ? "van-number-keyboard-input-select"
                          : "",
                      on: {
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          _vm.resultInputFlag = true
                          _vm.show = true
                          _vm.extraKey = "."
                          _vm.keyboard = "ZhuYuanHuLiFeiResult"
                          _vm.setZero("ZhuYuanHuLiFeiResult")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ZhuYuanHuLiFeiResult !== "",
                              expression: "ZhuYuanHuLiFeiResult !== ''",
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.ZhuYuanHuLiFeiResult)
                                      .wan > 0,
                                  expression:
                                    "yuanToWan(ZhuYuanHuLiFeiResult).wan > 0",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.yuanToWan(_vm.ZhuYuanHuLiFeiResult).wan
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.ZhuYuanHuLiFeiResult)
                                      .wan > 0,
                                  expression:
                                    "yuanToWan(ZhuYuanHuLiFeiResult).wan > 0",
                                },
                              ],
                              staticClass: "table-input-ji-wan",
                              staticStyle: { "font-size": "10px !important" },
                            },
                            [_vm._v("万")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.yuanToWan(_vm.ZhuYuanHuLiFeiResult).yuan
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" 元 "),
                ]),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        _vm.ZhuYuanHuLiFeiShow = !_vm.ZhuYuanHuLiFeiShow
                      },
                    },
                  },
                  [
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ZhuYuanHuLiFeiShow === false,
                          expression: "ZhuYuanHuLiFeiShow === false",
                        },
                      ],
                      attrs: { name: "arrow" },
                    }),
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ZhuYuanHuLiFeiShow === true,
                          expression: "ZhuYuanHuLiFeiShow === true",
                        },
                      ],
                      attrs: { name: "arrow-down" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ZhuYuanHuLiFeiShow === true,
                      expression: "ZhuYuanHuLiFeiShow === true",
                    },
                  ],
                  staticClass: "project-content",
                },
                [
                  _c(
                    "div",
                    { staticClass: "box", staticStyle: { padding: "10px 0" } },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ZhuYuanHuLiFei.params.checked === 1,
                            expression: "ZhuYuanHuLiFei.params.checked === 1",
                          },
                        ],
                        staticClass: "iconfont icon-danxuan",
                        on: {
                          click: function ($event) {
                            _vm.ZhuYuanHuLiFei.params.checked = 1
                          },
                        },
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ZhuYuanHuLiFei.params.checked !== 1,
                            expression: "ZhuYuanHuLiFei.params.checked !== 1",
                          },
                        ],
                        staticClass: "iconfont icon-yuanxingweixuanzhong",
                        on: {
                          click: function ($event) {
                            _vm.ZhuYuanHuLiFei.params.checked = 1
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v("护理人员有收入 护理人工资"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ZhuYuanHuLiFei.params.list[0].money,
                              expression: "ZhuYuanHuLiFei.params.list[0].money",
                            },
                          ],
                          staticClass: "table-input",
                          class:
                            _vm.keyboard ===
                              "ZhuYuanHuLiFei.params.list.0.money" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.ZhuYuanHuLiFei.params.list[0].money,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard =
                                "ZhuYuanHuLiFei.params.list.0.money"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.ZhuYuanHuLiFei.params.list[0],
                                "money",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("元/天*护理期限"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ZhuYuanHuLiFei.params.list[0].day,
                              expression: "ZhuYuanHuLiFei.params.list[0].day",
                            },
                          ],
                          staticClass: "table-input table-input-day",
                          class:
                            _vm.keyboard ===
                              "ZhuYuanHuLiFei.params.list.0.day" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.ZhuYuanHuLiFei.params.list[0].day,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "ZhuYuanHuLiFei.params.list.0.day"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.ZhuYuanHuLiFei.params.list[0],
                                "day",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("天"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "box", staticStyle: { padding: "10px 0" } },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ZhuYuanHuLiFei.params.checked === 2,
                            expression: "ZhuYuanHuLiFei.params.checked === 2",
                          },
                        ],
                        staticClass: "iconfont icon-danxuan",
                        on: {
                          click: function ($event) {
                            _vm.ZhuYuanHuLiFei.params.checked = 2
                          },
                        },
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ZhuYuanHuLiFei.params.checked !== 2,
                            expression: "ZhuYuanHuLiFei.params.checked !== 2",
                          },
                        ],
                        staticClass: "iconfont icon-yuanxingweixuanzhong",
                        on: {
                          click: function ($event) {
                            _vm.ZhuYuanHuLiFei.params.checked = 2
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v("雇佣护工 护理标准"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ZhuYuanHuLiFei.params.list[1].money,
                              expression: "ZhuYuanHuLiFei.params.list[1].money",
                            },
                          ],
                          staticClass: "table-input",
                          class:
                            _vm.keyboard ===
                              "ZhuYuanHuLiFei.params.list.1.money" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.ZhuYuanHuLiFei.params.list[1].money,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard =
                                "ZhuYuanHuLiFei.params.list.1.money"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.ZhuYuanHuLiFei.params.list[1],
                                "money",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("元/天*护理期限"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ZhuYuanHuLiFei.params.list[1].day,
                              expression: "ZhuYuanHuLiFei.params.list[1].day",
                            },
                          ],
                          staticClass: "table-input table-input-day",
                          class:
                            _vm.keyboard ===
                              "ZhuYuanHuLiFei.params.list.1.day" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.ZhuYuanHuLiFei.params.list[1].day,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "ZhuYuanHuLiFei.params.list.1.day"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.ZhuYuanHuLiFei.params.list[1],
                                "day",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("天 "),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ChuYuanHuLiFei.checked,
                  expression: "ChuYuanHuLiFei.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c("div", { staticClass: "project-title" }, [
                _c("div", { staticClass: "project-title-name" }, [
                  _vm._v(_vm._s(_vm.ChuYuanHuLiFei.text)),
                ]),
                _c("div", { staticClass: "project-title-input-box" }, [
                  _vm._v(" 计 "),
                  _c(
                    "div",
                    {
                      staticClass: "table-input-ji",
                      class:
                        _vm.keyboard === "ChuYuanHuLiFeiResult" && _vm.show
                          ? "van-number-keyboard-input-select"
                          : "",
                      on: {
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          _vm.resultInputFlag = true
                          _vm.show = true
                          _vm.extraKey = "."
                          _vm.keyboard = "ChuYuanHuLiFeiResult"
                          _vm.setZero("ChuYuanHuLiFeiResult")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ChuYuanHuLiFeiResult !== "",
                              expression: "ChuYuanHuLiFeiResult !== ''",
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.ChuYuanHuLiFeiResult)
                                      .wan > 0,
                                  expression:
                                    "yuanToWan(ChuYuanHuLiFeiResult).wan > 0",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.yuanToWan(_vm.ChuYuanHuLiFeiResult).wan
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.yuanToWan(_vm.ChuYuanHuLiFeiResult)
                                      .wan > 0,
                                  expression:
                                    "yuanToWan(ChuYuanHuLiFeiResult).wan > 0",
                                },
                              ],
                              staticClass: "table-input-ji-wan",
                              staticStyle: { "font-size": "10px !important" },
                            },
                            [_vm._v("万")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.yuanToWan(_vm.ChuYuanHuLiFeiResult).yuan
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" 元 "),
                ]),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        _vm.ChuYuanHuLiFeiShow = !_vm.ChuYuanHuLiFeiShow
                      },
                    },
                  },
                  [
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ChuYuanHuLiFeiShow === false,
                          expression: "ChuYuanHuLiFeiShow === false",
                        },
                      ],
                      attrs: { name: "arrow" },
                    }),
                    _c("van-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ChuYuanHuLiFeiShow === true,
                          expression: "ChuYuanHuLiFeiShow === true",
                        },
                      ],
                      attrs: { name: "arrow-down" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ChuYuanHuLiFeiShow === true,
                      expression: "ChuYuanHuLiFeiShow === true",
                    },
                  ],
                  staticClass: "project-content",
                },
                [
                  _c(
                    "div",
                    { staticClass: "box", staticStyle: { padding: "10px 0" } },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ChuYuanHuLiFei.params.checked === 1,
                            expression: "ChuYuanHuLiFei.params.checked === 1",
                          },
                        ],
                        staticClass: "iconfont icon-danxuan",
                        on: {
                          click: function ($event) {
                            _vm.ChuYuanHuLiFei.params.checked = 1
                          },
                        },
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ChuYuanHuLiFei.params.checked !== 1,
                            expression: "ChuYuanHuLiFei.params.checked !== 1",
                          },
                        ],
                        staticClass: "iconfont icon-yuanxingweixuanzhong",
                        on: {
                          click: function ($event) {
                            _vm.ChuYuanHuLiFei.params.checked = 1
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v("护理人员有收入 护理人工资"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ChuYuanHuLiFei.params.list[0].money,
                              expression: "ChuYuanHuLiFei.params.list[0].money",
                            },
                          ],
                          staticClass: "table-input",
                          class:
                            _vm.keyboard ===
                              "ChuYuanHuLiFei.params.list.0.money" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.ChuYuanHuLiFei.params.list[0].money,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard =
                                "ChuYuanHuLiFei.params.list.0.money"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.ChuYuanHuLiFei.params.list[0],
                                "money",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("元/天*护理期限"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ChuYuanHuLiFei.params.list[0].day,
                              expression: "ChuYuanHuLiFei.params.list[0].day",
                            },
                          ],
                          staticClass: "table-input table-input-day",
                          class:
                            _vm.keyboard ===
                              "ChuYuanHuLiFei.params.list.0.day" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.ChuYuanHuLiFei.params.list[0].day,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "ChuYuanHuLiFei.params.list.0.day"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.ChuYuanHuLiFei.params.list[0],
                                "day",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("天"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "box", staticStyle: { padding: "10px 0" } },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ChuYuanHuLiFei.params.checked === 2,
                            expression: "ChuYuanHuLiFei.params.checked === 2",
                          },
                        ],
                        staticClass: "iconfont icon-danxuan",
                        on: {
                          click: function ($event) {
                            _vm.ChuYuanHuLiFei.params.checked = 2
                          },
                        },
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ChuYuanHuLiFei.params.checked !== 2,
                            expression: "ChuYuanHuLiFei.params.checked !== 2",
                          },
                        ],
                        staticClass: "iconfont icon-yuanxingweixuanzhong",
                        on: {
                          click: function ($event) {
                            _vm.ChuYuanHuLiFei.params.checked = 2
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v("雇佣护工 护理标准"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ChuYuanHuLiFei.params.list[1].money,
                              expression: "ChuYuanHuLiFei.params.list[1].money",
                            },
                          ],
                          staticClass: "table-input",
                          class:
                            _vm.keyboard ===
                              "ChuYuanHuLiFei.params.list.1.money" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.ChuYuanHuLiFei.params.list[1].money,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard =
                                "ChuYuanHuLiFei.params.list.1.money"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.ChuYuanHuLiFei.params.list[1],
                                "money",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("元/天*护理期限"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ChuYuanHuLiFei.params.list[1].day,
                              expression: "ChuYuanHuLiFei.params.list[1].day",
                            },
                          ],
                          staticClass: "table-input table-input-day",
                          class:
                            _vm.keyboard ===
                              "ChuYuanHuLiFei.params.list.1.day" && _vm.show
                              ? "van-number-keyboard-input-select"
                              : "",
                          attrs: { readonly: "", clickable: "" },
                          domProps: {
                            value: _vm.ChuYuanHuLiFei.params.list[1].day,
                          },
                          on: {
                            touchstart: function ($event) {
                              $event.stopPropagation()
                              _vm.resultInputFlag = false
                              _vm.show = true
                              _vm.extraKey = "."
                              _vm.keyboard = "ChuYuanHuLiFei.params.list.1.day"
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.ChuYuanHuLiFei.params.list[1],
                                "day",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("天 "),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ShangCanPeiChang.checked,
                  expression: "ShangCanPeiChang.checked",
                },
              ],
              staticClass: "project",
            },
            [
              _c(
                "div",
                {
                  staticClass: "project-title",
                  on: {
                    click: function ($event) {
                      _vm.ShangCanPeiChang.show = !_vm.ShangCanPeiChang.show
                    },
                  },
                },
                [
                  _c("div", [_vm._v(_vm._s(_vm.ShangCanPeiChang.text))]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ShangCanPeiChang.result,
                          expression: "ShangCanPeiChang.result",
                        },
                      ],
                    },
                    [
                      _vm._v(" 计"),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.ShangCanPeiChang.result)),
                      ]),
                      _vm._v("元 "),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c("van-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ShangCanPeiChang.show === false,
                            expression: "ShangCanPeiChang.show === false",
                          },
                        ],
                        attrs: { name: "arrow" },
                      }),
                      _c("van-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ShangCanPeiChang.show === true,
                            expression: "ShangCanPeiChang.show === true",
                          },
                        ],
                        attrs: { name: "arrow-down" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ShangCanPeiChang.show === true,
                      expression: "ShangCanPeiChang.show === true",
                    },
                  ],
                  staticClass: "project-content",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box",
                      staticStyle: {
                        padding: "10px 0",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#999999",
                                "font-size": "12px !important",
                              },
                            },
                            [_vm._v("最高伤残等级")]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#0099ff",
                                padding: "0 10px",
                                display: "flex",
                                "align-items": "center",
                                width: "80px !important",
                              },
                            },
                            [
                              _c("van-field", {
                                staticStyle: {
                                  flex: "1",
                                  padding: "0",
                                  "text-align": "center",
                                  color: "#0099ff",
                                },
                                attrs: { readonly: "", clickable: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.firstDisabledShow = true
                                  },
                                },
                                model: {
                                  value:
                                    _vm.ShangCanPeiChang.params.firstDisabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ShangCanPeiChang.params,
                                      "firstDisabled",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ShangCanPeiChang.params.firstDisabled",
                                },
                              }),
                              _c("van-icon", { attrs: { name: "arrow-down" } }),
                              _c(
                                "van-popup",
                                {
                                  attrs: { position: "top" },
                                  model: {
                                    value: _vm.firstDisabledShow,
                                    callback: function ($$v) {
                                      _vm.firstDisabledShow = $$v
                                    },
                                    expression: "firstDisabledShow",
                                  },
                                },
                                [
                                  _c(
                                    "van-radio-group",
                                    {
                                      on: { change: _vm.firstDisabledChange },
                                      model: {
                                        value:
                                          _vm.ShangCanPeiChang.params
                                            .firstDisabled,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ShangCanPeiChang.params,
                                            "firstDisabled",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ShangCanPeiChang.params.firstDisabled",
                                      },
                                    },
                                    [
                                      _c(
                                        "van-cell-group",
                                        _vm._l(
                                          _vm.firstDisabled,
                                          function (item, i) {
                                            return _c(
                                              "van-cell",
                                              {
                                                key: i,
                                                attrs: {
                                                  clickable: "",
                                                  title: item.label,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onClickFirstDisabled(
                                                      item,
                                                      i
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("van-radio", {
                                                  ref: "checkboxes",
                                                  refInFor: true,
                                                  attrs: {
                                                    slot: "right-icon",
                                                    name: item.label,
                                                    shape: "square",
                                                  },
                                                  slot: "right-icon",
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#999999",
                                "font-size": "12px !important",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDisabledPercentage(
                                      "first",
                                      _vm.ShangCanPeiChang.params.firstDisabled
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: { color: "#999999" },
                          on: {
                            click: function ($event) {
                              return _vm.addSecondDisabled(-1)
                            },
                          },
                        },
                        [
                          _vm._v("增加次级伤残"),
                          _c("van-icon", { attrs: { name: "arrow-down" } }),
                        ],
                        1
                      ),
                      _c(
                        "van-popup",
                        {
                          attrs: { position: "top" },
                          model: {
                            value: _vm.secondDisabledShow,
                            callback: function ($$v) {
                              _vm.secondDisabledShow = $$v
                            },
                            expression: "secondDisabledShow",
                          },
                        },
                        [
                          _c(
                            "van-radio-group",
                            {
                              on: { change: _vm.secondDisabledChange },
                              model: {
                                value: _vm.secondDisabledValue,
                                callback: function ($$v) {
                                  _vm.secondDisabledValue = $$v
                                },
                                expression: "secondDisabledValue",
                              },
                            },
                            [
                              _c(
                                "van-cell-group",
                                _vm._l(
                                  _vm.secondDisabledCopy,
                                  function (item, i) {
                                    return _c(
                                      "van-cell",
                                      {
                                        key: i,
                                        attrs: {
                                          clickable: "",
                                          title: item.label,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onClickSecondDisabled(
                                              item,
                                              i
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("van-radio", {
                                          ref: "checkboxesSecond",
                                          refInFor: true,
                                          attrs: {
                                            slot: "right-icon",
                                            name: item.label,
                                            shape: "square",
                                          },
                                          slot: "right-icon",
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "box",
                      staticStyle: {
                        padding: "10px 0",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "div",
                        _vm._l(
                          _vm.ShangCanPeiChang.params.secondDisabled,
                          function (disabled, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { color: "#999999" } },
                                  [_vm._v("次级伤残")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#0099ff",
                                      padding: "0 10px",
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("van-field", {
                                      staticStyle: {
                                        width: "50px",
                                        flex: "1",
                                        padding: "0",
                                        "text-align": "center",
                                        color: "#0099ff",
                                      },
                                      attrs: { readonly: "", clickable: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addSecondDisabled(
                                            i,
                                            disabled.label
                                          )
                                        },
                                      },
                                      model: {
                                        value: disabled.label,
                                        callback: function ($$v) {
                                          _vm.$set(disabled, "label", $$v)
                                        },
                                        expression: "disabled.label",
                                      },
                                    }),
                                    _c("van-icon", {
                                      attrs: { name: "arrow-down" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { color: "#999999" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getDisabledPercentage(
                                            "second",
                                            disabled.label
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.time !== "" && _vm.address !== "",
                          expression: "time !== '' && address !== ''",
                        },
                      ],
                      staticClass: "box",
                      staticStyle: {
                        padding: "10px 0",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c("div", [
                        _c("span", [
                          _c("span", [_vm._v(_vm._s(parseInt(_vm.time) - 1))]),
                          _vm._v("年 "),
                        ]),
                        _c("span", [
                          _c("span", [_vm._v(_vm._s(_vm.address))]),
                          _vm._v("人均可支配收入 "),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.ShangCanPeiChangIncomeInputShow,
                                expression: "!ShangCanPeiChangIncomeInputShow",
                              },
                            ],
                            staticStyle: {
                              color: "#0099ff",
                              cursor: "pointer",
                              padding: "0 5px",
                            },
                            on: {
                              click: function ($event) {
                                _vm.ShangCanPeiChangIncomeInputShow = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.ShangCanPeiChang.params.income) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ShangCanPeiChangIncomeInputShow,
                                expression: "ShangCanPeiChangIncomeInputShow",
                              },
                            ],
                            staticStyle: { display: "inline-block" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.ShangCanPeiChang.params.income,
                                  expression: "ShangCanPeiChang.params.income",
                                },
                              ],
                              staticClass: "table-input",
                              class:
                                _vm.keyboard ===
                                  "ShangCanPeiChang.params.income" && _vm.show
                                  ? "van-number-keyboard-input-select"
                                  : "",
                              attrs: { readonly: "", clickable: "" },
                              domProps: {
                                value: _vm.ShangCanPeiChang.params.income,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.addIncome("ShangCanPeiChang")
                                },
                                touchstart: function ($event) {
                                  $event.stopPropagation()
                                  _vm.resultInputFlag = false
                                  _vm.show = true
                                  _vm.extraKey = "."
                                  _vm.keyboard =
                                    "ShangCanPeiChang.params.income"
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.ShangCanPeiChang.params,
                                    "income",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                        _c("span", [
                          _vm._v(
                            " 元*(" +
                              _vm._s(this.getShangCanPercentage().first * 100) +
                              "%+" +
                              _vm._s(
                                this.getShangCanPercentage().second * 100
                              ) +
                              "%)*"
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.ShangCanPeiChang.params.year) + "年"
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.insurance &&
                    (_vm.SiWangPeiChangJin.checked || _vm.YiLiaoFei.checked),
                  expression:
                    "insurance && (SiWangPeiChangJin.checked || YiLiaoFei.checked)",
                },
              ],
              staticClass: "project",
            },
            [
              _c("div", { staticClass: "project-title" }, [
                _c("div", [_vm._v("交强险责任分摊")]),
              ]),
              _c("div", { staticClass: "project-content" }, [
                _c(
                  "div",
                  { staticClass: "box", staticStyle: { padding: "10px 0" } },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isResponsibility === 1,
                          expression: "isResponsibility === 1",
                        },
                      ],
                      staticClass: "iconfont icon-danxuan",
                      on: {
                        click: function ($event) {
                          _vm.isResponsibility = 1
                        },
                      },
                    }),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isResponsibility !== 1,
                          expression: "isResponsibility !== 1",
                        },
                      ],
                      staticClass: "iconfont icon-yuanxingweixuanzhong",
                      on: {
                        click: function ($event) {
                          _vm.isResponsibility = 1
                        },
                      },
                    }),
                    _c("span", [_vm._v("有责任")]),
                    _c(
                      "span",
                      { staticStyle: { flex: "1", "margin-left": "20px" } },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.SiWangPeiChangJin.checked,
                                expression: "SiWangPeiChangJin.checked",
                              },
                            ],
                          },
                          [_vm._v("死亡伤残赔偿限额：-180000元；")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.YiLiaoFei.checked,
                                expression: "YiLiaoFei.checked",
                              },
                            ],
                          },
                          [_vm._v("医疗费用赔偿限额：-18000元；")]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "box", staticStyle: { padding: "10px 0" } },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isResponsibility === 2,
                          expression: "isResponsibility === 2",
                        },
                      ],
                      staticClass: "iconfont icon-danxuan",
                      on: {
                        click: function ($event) {
                          _vm.isResponsibility = 2
                        },
                      },
                    }),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isResponsibility !== 2,
                          expression: "isResponsibility !== 2",
                        },
                      ],
                      staticClass: "iconfont icon-yuanxingweixuanzhong",
                      on: {
                        click: function ($event) {
                          _vm.isResponsibility = 2
                        },
                      },
                    }),
                    _c("span", [_vm._v("无责任")]),
                    _c(
                      "span",
                      { staticStyle: { flex: "1", "margin-left": "20px" } },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.SiWangPeiChangJin.checked,
                                expression: "SiWangPeiChangJin.checked",
                              },
                            ],
                          },
                          [_vm._v("死亡伤残赔偿限额：-18000元；")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.YiLiaoFei.checked,
                                expression: "YiLiaoFei.checked",
                              },
                            ],
                          },
                          [_vm._v("医疗费用赔偿限额：-1800元；")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.result !== "",
                  expression: "this.result !== ''",
                },
              ],
              staticClass: "deathCompensation-result",
            },
            [
              _c("div"),
              _c("div", [
                _vm._v(" 合计"),
                _c("span", { staticStyle: { color: "#ed3f3e" } }, [
                  _vm._v(_vm._s(this.result)),
                ]),
                _vm._v("元 "),
              ]),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.result !== "",
                    expression: "this.result !== ''",
                  },
                ],
              }),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.result === "",
                    expression: "this.result === ''",
                  },
                ],
              }),
            ]
          ),
          _c("div", { staticClass: "interest-result" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.law,
                    expression: "law",
                  },
                ],
                on: {
                  click: function ($event) {
                    _vm.isShowInfo = !_vm.isShowInfo
                  },
                },
              },
              [_vm._v("法条")]
            ),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.law,
                  expression: "!law",
                },
              ],
            }),
            _c("span", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.result !== "",
                      expression: "this.result !== ''",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.copyAll },
                },
                [_vm._v("复制")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.result !== "" && !_vm.iphone,
                      expression: "this.result !== '' && !iphone",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.downLoad },
                },
                [_vm._v("下载")]
              ),
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.share },
                },
                [_vm._v("分享")]
              ),
              _c("span", { on: { click: _vm.feedback } }, [_vm._v("反馈")]),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowInfo,
                  expression: "isShowInfo",
                },
              ],
              staticClass: "interest-result",
            },
            [_c("div", { domProps: { innerHTML: _vm._s(_vm.law) } })]
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.startTimeShow,
            callback: function ($$v) {
              _vm.startTimeShow = $$v
            },
            expression: "startTimeShow",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              title: "选择死亡时间",
              "show-toolbar": "",
              columns: _vm.columns,
              "default-index": 50,
            },
            on: {
              confirm: _vm.startTimeConfirm,
              cancel: function ($event) {
                _vm.startTimeShow = false
              },
            },
          }),
        ],
        1
      ),
      _vm.errorCorrectionDialog
        ? _c("error-correction", {
            attrs: {
              time: _vm.errorCorrection.time,
              unit: _vm.errorCorrection.unit,
              address: _vm.errorCorrection.address,
              name: _vm.errorCorrection.name,
              value: _vm.errorCorrection.value,
            },
            on: { close: _vm.closeErrorCorrectionDialog },
          })
        : _vm._e(),
      _c("van-number-keyboard", {
        attrs: {
          show: _vm.show,
          theme: "custom",
          "extra-key": _vm.extraKey,
          "close-button-text": "完成",
          title: _vm.keyboardTitle,
        },
        on: {
          blur: function ($event) {
            _vm.show = false
            _vm.resultInputFlag = false
            _vm.keyboardTitle = ""
            _vm.keyboard = ""
            _vm.ShangCanPeiChangIncomeBlur()
          },
          input: _vm.onInput,
          delete: _vm.onDelete,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }